$table-header-background: $color-gray-light !default;
$table-border-color: $color-green-light !default;
$table-tr-hover-bg: #f5f5f5;

.table-wrapper {
	overflow-x: auto;
}

.table {
	width: 100%;
	border-collapse: collapse;

	thead th {
		padding: 16px;
		background: $table-header-background;
		font-weight: 400;
		text-align: left;
		white-space: nowrap;
	}

	td {
		&:first-child {
			font-weight: 700;
		}

		padding: 24px 16px;
		border-bottom: 1px solid $table-border-color;
		white-space: nowrap;

		&.long-text {
			white-space: normal;
			min-width: 200px;
		}
	}

	td.reset-white-space {
		white-space: normal;
	}
}

.table--linked {
	tr {
		transition: background-color 0.2s ease-in-out;
		cursor: pointer;

		&:hover {
			background-color: $table-tr-hover-bg;
		}
	}

	td {
		padding: 0;
		a {
			display: block;
			padding: 24px 16px;
			width: 100%;

			&:hover {
				color: $global-font-color;
			}
		}

		.small--padding {
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}
}
