$divider-color: $color-green-light !default;

.divider {
	border-bottom: 1px solid $divider-color;
	margin: 32px 0;

	&--sm {
		margin: 24px 0;
	}
}
