// Hide right side padding on the popup
.gm-style-iw-d {
	overflow: hidden !important;
}

// Hide left side padding on the popup and remove border radius
.gm-style .gm-style-iw-c {
	padding: 0 !important;
	border-radius: 0 !important;
}
