/**
	* Popup classes used for positioning Popup on Map.
 */

/* The popup bubble styling. */
.popup-bubble {
	/* Position the bubble centred-above its parent. */
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -100%);
	/* Style the bubble. */
	font-family: 'EuclidCircular', sans-serif;
	box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
	z-index: 14;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
	/* Position the div a fixed distance above the tip. */
	position: absolute;
	width: 100%;
	bottom: 8px;
	left: 0;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
	cursor: auto;
	height: 0;
	position: absolute;
	/* The max width of the info window. */
	width: 300px;
	margin-top: -28px; // move popup above the pin
}
