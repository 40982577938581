.confirm-new-schedule-container {
	margin-top: 32px;
	margin-bottom: 120px;
	.confirm-new-schedule-header {
		max-width: 554px;
	}

	.confirm-new-schedule-content {
		max-width: 320px;
		margin-top: 64px;

		.booking-card-document:last-child {
			border-bottom: none;
		}
	}

	.confirm-new-schedule-button-group {
		display: flex;
		align-items: center;
		margin-top: 28px;

		* + * {
			@include spacing(left, 32px);
		}
	}
}
