$video-play-button-size: 60px;

.video-container {
	max-width: 908px;
}

.video {
	position: relative;
}

.video video {
	height: auto;
	// Without defining the aspect-ratio property, browsers will ignore the height for
	// responsive video elements and use a default aspect-ratio of 2:1.
	// @see https://github.com/whatwg/html/issues/3090
	aspect-ratio: 16 / 9;
}

.video iframe {
	max-width: 884px;
	max-height: 474px;
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
}

.video__button,
.video__overlay {
	display: flex;
	align-items: center;
	justify-content: center;
}

.video__overlay {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.35);
	transition: opacity 0.2s ease-in-out;
	cursor: pointer;

	&.hide {
		opacity: 0;
		pointer-events: none;
	}
}

.video__button {
	background-color: white;
	color: $color-orange-primary;
	height: $video-play-button-size;
	width: $video-play-button-size;
	border-radius: 50%;
	font-size: 64px;
	padding: 0;
	border: 0;
	outline: none;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

// Hover state

.video__overlay:hover .video__button {
	color: #fff;
	background-color: $color-green-primary;
}
