.slider {
	padding-bottom: 80px;
}

.slider__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.slider__controls {
	display: none;
}

.slider__body {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.slider__card {
	display: block;
	cursor: pointer;
}

.slider__card-image {
	width: 248px;
	height: 170px;
	object-fit: cover;
	flex: 0 0 248px;
}

@include desktop {
	.slider__controls {
		display: block;
	}
}
