$hero-padding-y: 32px; // set with var
$hero-padding-x: $container-mobile-padding;
$hero-image-height: 327px;
$hero-image-width: calc(100% - #{$hero-padding-x} * 2);
$hero-image-height-half: ($hero-image-height / 2);

// Tokens for Countries - Desktop
$hero-countries-padding-left: 267px;

.hero-image__container {
	display: flex;
	flex-direction: column;
}

.hero-image__wrapper {
	position: relative;
	display: flex;
	flex-direction: column-reverse;

	@include small {
		//Helper class to fix cut top of some images
		.hero-image__image .object-position--top {
			object-position: top;
		}
	}

	@include laptop {
		flex-direction: row;
		align-items: flex-start;
		padding-right: $hero-padding-x;
	}
}

.hero-image__image {
	position: absolute;
	bottom: 0;
	transform: translateY(50%);
	width: $hero-image-width;
	height: $hero-image-height;
	left: 0;
	right: 0;
	margin: auto;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include laptop {
		position: initial;
		margin: initial;
		transform: translateY(0);
	}
}

.hero-image__info {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: $color-brand-secondary;
	padding: $hero-padding-y $hero-padding-x $hero-image-height-half $hero-padding-x;
}

.hero-image__heading {
	@extend .heading-hero;
	line-height: 1;
}

.hero-image__divider {
	background-color: $color-brand-primary;
	width: 100%;
	padding: $hero-image-height-half $hero-padding-x $hero-padding-y $hero-padding-x;
}

@include laptop {
	$hero-image-height: 416px;
	$hero-image-width: 60%;
	$hero-padding-x: $container-desktop-padding;
	$hero-breadcrumb-height: 56px;

	.hero-image__image {
		height: $hero-image-height;
		width: $hero-image-width;
	}

	.hero-image__info {
		position: absolute;
		top: -$hero-breadcrumb-height;
		@include flip(right, 0);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		width: 50%;
		height: calc(100% + #{$hero-breadcrumb-height} * 2);

		padding: 126px 64px;
	}

	.hero-image__divider {
		width: calc(100% - #{$hero-padding-x});
	}

	.hero-image__divider--small {
		padding-top: 124px;
	}
}
