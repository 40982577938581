.link-carousel {
	padding-top: 32px;
	padding-bottom: 32px;
}

.link-carousel-links-container {
	background-color: $color-green-primary;
	margin-top: 32px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	overflow: auto;
}

.link-carousel-link {
	background-color: $color-green-primary;
	color: #fff;
	white-space: nowrap;
	border: none;
	cursor: pointer;

	.icon {
		display: none;
	}

	&:hover {
		color: $color-orange-light;
		text-decoration: underline;
		text-underline-offset: 2px;
	}
}

.link-carousel-link.active {
	color: $color-orange-light;
	text-decoration: underline;
	text-underline-offset: 2px;
}

.link-carousel-link + .link-carousel-link {
	@include spacing(left, 24px);
}

.link-carousel-content-container {
	background-color: $color-orange-light;
	padding-top: 32px;
	padding-bottom: 32px;
}

.link-carousel-content-image {
	width: 100%;
	transform: translate(0, -16px);
	margin-bottom: -16px;
}

.link-carousel-content-description {
	margin-top: 24px;
}

@include laptop {
	.link-carousel {
		padding-top: 0px;
		padding-bottom: 80px;
	}

	.link-carousel-title {
		max-width: 1142px;
		margin: 0 auto;
		padding: 64px 0px;
	}

	.link-carousel-col-two {
		display: flex;
		position: relative;
	}

	.link-carousel-links-container {
		align-self: flex-start;
		flex-direction: column;
		flex-basis: 37%;
		margin-top: 40px;
		margin-bottom: 40px;
		padding-top: 36px;
		padding-bottom: 32px;
		font-size: 24px;
	}

	.link-carousel-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.icon {
			display: initial;
			opacity: 0;
			font-size: 32px;
			transform: translateX(-32px);
			@include transition('transform, opacity', 0.3s, ease-in);

			@include rtl {
				/**
				* Rotating icon here because if there are more transformations, they must be in a same place.
				*/
				transform: translateX(32px) rotate(-180deg);
			}
		}
	}

	.link-carousel-link.active {
		color: $color-orange-light;
		text-decoration: underline;
		text-underline-offset: 2px;

		.icon {
			opacity: 1;
			transform: translateX(0);

			@include rtl {
				/**
				* Rotating icon here because if there are more transformations, they must be in a same place.
				*/
				transform: translateX(0) rotate(-180deg);
			}
		}
	}

	.link-carousel-link + .link-carousel-link {
		margin-left: 0;
		margin-right: 0;
		margin-top: 32px;
	}

	.link-carousel-link span {
		@include spacing(right, 24px);
	}

	.link-carousel-content-container {
		position: relative;
		align-items: flex-start;
		flex-basis: 63%;
		@include padding(left, 116px);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			@include flip(right, 0);
			width: 120%;
			height: 100%;
			background-color: $color-orange-light;
			z-index: -1;
		}
	}

	.link-carousel-content-container.active {
		display: flex;
	}

	.link-carousel-content-image {
		width: 60%;
		transform: translate(0, 0);
		margin-top: 64px;
		margin-bottom: 0;
	}

	.link-carousel-content {
		padding-top: 36px;
	}

	.link-carousel-content-description {
		font-size: 24px;
	}
}
