.page-404-container {
	margin-top: 0;
}

.page-404-content .home-media-text-image {
	display: block;
}

.page-404-container:before {
	width: 88%;
}

.page-404-content {
	padding-bottom: 0;
	padding-top: 74px;
}

.page-404-text {
	padding-bottom: 48px;
}

@include media-breakpoint-down(laptop) {
	.page-404-content {
		padding-top: 0;
	}

	.page-404-content .home-media-text-image-container {
		transform: translate(0, 0);
		margin-bottom: 32px;
		margin-top: 32px;
	}
}

@include laptop {
	.page-404-container {
		margin-top: auto;
		margin-bottom: auto;
	}

	.page-404-content .home-media-text-image {
		margin-bottom: -1px;
	}

	.page-404-text {
		padding-bottom: 75px;
	}
}
