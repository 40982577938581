.d-inline {
	display: inline;
}

.d-block {
	display: block;
}

.mobile-only {
	display: none;
}

.above-desktop-only {
	display: block;
}

.bellow-desktop-only {
	display: none;
}

@include below-laptop {
	.laptop-only {
		display: none;
	}

	.mobile-only {
		display: block;
	}
}

@include below-desktop {
	.above-desktop-only {
		display: none;
	}

	.bellow-desktop-only {
		display: block;
	}
}

// cursor
.cursor-pointer {
	cursor: pointer;
}
