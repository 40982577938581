.no-bookings__slider-header {
	padding: 32px 0;
	border-top: 1px solid $color-green-light;
}

@include laptop {
	.no-bookings .sidebar-container__sidebar {
		flex: 0 0 30%;
	}

	.no-bookings__content-section {
		flex: 0 0 70%;
		overflow: hidden;
	}
}
