$split-section-border-color: $color-green-light !default;

.split-section {
	margin-bottom: 32px;
	padding-bottom: 32px;
	border-bottom: 1px solid $split-section-border-color;
	max-width: 760px;

	&--plain {
		border: 0;
	}

	&:last-child.split-section--plain {
		margin-bottom: 0;
	}
}

.split-section__info {
	margin-bottom: 24px;
	max-width: 320px;
}

@include media-breakpoint-down(tablet) {
	.split-section {
		.split-section__content.button-group {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			> .button + .button {
				margin: 0;
				margin-top: 24px;
			}
		}
	}
}

@include tablet {
	.split-section {
		display: flex;

		.split-section__content.button-group {
			> .button + .button {
				margin: 0;
				margin-inline-start: 32px;
			}
		}
	}

	.split-section__info {
		flex: 1 0 40%;
		margin: 0;
		padding-inline-end: 32px;
	}

	.split-section__content {
		flex: 1 1 60%;
		max-width: 440px;
	}
}

@include laptop {
	.split-section__info {
		flex-basis: 320px;
	}
}
