$upload-file-transparent-background-color: #f8f8f8 !default;
$upload-file-button-background-color: transparent !default;
$upload-file-button-color: $color-brand-primary !default;
$upload-file-button-background-hover-color: $color-brand-primary !default;

.upload__file-section {
	max-width: $container-sm-width;
	width: 100%;

	&--transparent {
		padding-bottom: 124px;
	}
}

.upload__file-input {
	position: fixed;
	top: -100px;
	visibility: hidden;
	width: 0;
	height: 0;
}

.upload__file {
	position: relative;

	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 203px;
	max-width: 100%;
	height: 203px;

	color: $color-brand-primary;
	background-image: url('/assets/default-staff-image.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&-image {
		.upload__file-button {
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

			&,
			&:hover,
			&:focus-visible {
				background-color: $color-white;
				color: $color-brand-primary;
			}
		}

		.upload__file-preview {
			background-color: transparent;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			display: none;
			width: 100%;
			height: 100%;
			flex-shrink: 0;
		}
	}

	.upload__file-edit,
	.upload__file-delete {
		@extend %reset-button;

		display: none;
		position: absolute;
		bottom: 12px;

		border-radius: 50%;

		padding: 7px 8px;
		width: 40px;
		height: 40px;

		background-color: $color-white;
		color: $color-brand-primary;
		line-height: 24px;
		font-size: 1rem;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.upload__file-edit {
		@include flip(right, 12px);
	}

	.upload__file-delete {
		@include flip(right, 60px);
	}
}

.upload__file--transparent {
	position: relative;
	border: 1.5px dashed #bacbc2;
	display: flex;
	width: 100%;
	height: 436px;
	background-color: $upload-file-transparent-background-color;

	.upload__file--button-upload {
		background-color: $upload-file-button-background-color;
		border: 1px solid $upload-file-button-border-color;
		color: $upload-file-button-color;

		&:hover {
			background-color: $upload-file-button-background-hover-color;
			color: white;
		}
	}

	.upload__file-create {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-align: center;

		.upload__file-title {
			font-size: 20px;
			font-weight: 500;
		}

		.upload__file-title,
		.upload__file-subtitle {
			padding: 0 10px;
		}
	}

	.upload__file-delete {
		display: none;
		position: absolute;
		bottom: 24px;
		@include flip(right, 24px);
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
		transition: box-shadow 0.3s;

		&:hover {
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
		}

		&,
		&:hover,
		&:focus-visible {
			background-color: $color-white;
			color: $color-brand-primary;
		}
	}
}

.upload__file-uploaded {
	.upload__file-create {
		display: none;
	}

	.upload__file-edit,
	.upload__file-delete,
	.upload__file-remove {
		display: block;
	}

	.upload__file-preview {
		display: block;
	}
}

// Temp solution until we refactor scss and class name containers
@include media-breakpoint-down(tablet) {
	.upload__file-section {
		.button-group {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			> .button + .button {
				margin: 0;
				margin-top: 24px;
			}
		}
	}
}
