$logged-user-email-color: $color-green-light !default;
$account-box-container-border: $color-green-light !default;

.account-details__info-box-container {
	padding-bottom: 16px;
	border-bottom: 1px solid $account-box-container-border;
}

.logged-user-email {
	color: $logged-user-email-color;
	cursor: default;

	&:hover {
		background-color: transparent;
		color: $logged-user-email-color;
	}
}

.account-details .email-verified {
	margin-top: 24px;
}

.account-details__download-button:hover .download-data__text {
	border-bottom: 1px solid #e86825;
}

.download-data__icon {
	font-size: 24px;
}

.download-data__text {
	border-bottom: 1px solid white;
	line-height: 1.4;
	padding: 0 0 2px;
	transition: border 0.2s ease-in-out;
}

.account-details .split-section:last-child {
	border-bottom: none;
}

@include media-breakpoint-down(laptop) {
	.logged-user-email {
		padding: 0;
	}
}

@include tablet {
	.account-details .split-section__info {
		min-width: 320px;
	}
	.account-details .split-section__info {
		flex: 0 1 auto;
	}
	.account-details .split-section__content {
		flex: 1 1 auto;
	}
}
