$tooltip-background: #fff;
$tooltip-padding: 18px;
$tooltip-arrow-height: 22px;

$_tooltip-arrow-diagonal: 1.41421356237 * $tooltip-arrow-height; // sqrt(2) = 1.41421356237

[data-tooltip] {
	cursor: pointer;
	line-height: 1;
}

.tooltip {
	position: absolute;
	padding: $tooltip-padding;
	max-width: 320px;
	border: 1px solid $tooltip-border-color;
	background-color: $tooltip-background;
	box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
	@include z-index('tooltip');
}

.tooltip__arrow {
	position: absolute;
	background: $tooltip-background;
	border: 1px solid $tooltip-border-color;
	border-width: 1px 1px 0 0;
	width: $tooltip-arrow-height;
	height: $tooltip-arrow-height;
	transform: rotate(45deg);
}
