$data-list-title-color: $color-orange-dark !default;
$data-list-text-transfrom: uppercase !default;
$data-list-font-size: 12px !default;
$data-list-letter-spacing: 2.4px !default;
$data-list-divider-border-color: $color-green-light !default;

.data-list {
	margin: 0;

	.data-title {
		color: $data-list-title-color;
		text-transform: $data-list-text-transfrom;
		font-size: $data-list-font-size;
		letter-spacing: $data-list-letter-spacing;
	}

	.data-description + .data-title {
		margin-top: 32px;
	}

	.data-description {
		margin-top: 8px;
		@include spacing(left, 0px);
	}

	.booking-tick {
		width: 24px;
		height: 24px;
		color: $color-orange-primary;
		@include spacing(right, 8px);
	}

	.dt-divider {
		padding-top: 32px;
		border-top: 1px solid $data-list-divider-border-color;
	}
}
