$price-font-size-price: 24px;
$price-font-size-unit: 14px;
$price-spacing-x-unit: 12px;
$price-font-weight-price: initial !default;

.price__container {
	display: flex;
	justify-content: center;
}

.price__price {
	font-size: $price-font-size-price;
	font-weight: $price-font-weight-price;
}

.price__unit {
	@include spacing(left, $price-spacing-x-unit);
	font-size: $price-font-size-unit;
}
