.t-12 {
	font-size: 12px !important;
}

.t-16 {
	font-size: 16px !important;
}

.t-18 {
	font-size: 18px !important;
}

.t-20 {
	font-size: 20px !important;
}

.t-24 {
	font-size: 24px !important;
}

.text-mute {
	opacity: 0.65;
}

.text-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-uppercase {
	text-transform: uppercase;
}

.text--light-green {
	color: $color-green-medium;
}

.fw-500 {
	font-weight: $font-weight-medium;
}

.word-break {
	word-break: break-all;
}
