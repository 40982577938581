$nl-section-background: $color-gray-light;
$nl-header-desktop-width: 80%;
$nl-header-background: $color-brand-primary;
$nl-header-color: $color-white;

.newsletter-library {
	background-color: $nl-section-background;
	width: 100%;
}

.newsletter-library__header {
	padding-top: $home-hero-image-height / 2;
	background-color: $nl-header-background;
	color: $nl-header-color;
}

.newsletter-library__nav,
.newsletter-library__title,
.newsletter-library__subheader,
.newsletter-library__slider,
.newsletter-library__controls {
	// Imitate a container
	padding: 0 $container-mobile-padding;
}

.newsletter-library__title {
	margin: 24px 0;
}

.newsletter-library__nav {
	display: flex;
	align-items: center;
	height: 48px;
	overflow-y: hidden;
	overflow-x: auto;
}

.newsletter-library__link {
	@extend %reset-button;
	color: #fff;
	white-space: nowrap;

	&.active {
		border-bottom: 2px solid #fff;
	}
	& + & {
		margin-inline-start: 24px;
	}
}

.newsletter-library__subheader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 32px 0 24px 0;
}

.newsletter-library__controls {
	margin-top: 16px;
	padding-bottom: 24px;
}

.newsletter-library__control {
	@extend %reset-button;
	font-size: 24px;
	color: $color-brand-primary;
	transition: opacity 0.2s ease-in-out;

	& + & {
		margin-inline-start: 12px;
	}

	&.glide__arrow--disabled {
		opacity: 0.5;
	}
}

.newsletter-library__letter {
	display: block;
}

.newsletter-library__letter-image {
	margin-bottom: 24px;
	flex: 0 0 auto;
	width: 303px;
	height: 280px;
	object-fit: cover;
}

@include laptop {
	.newsletter-library__header {
		padding-top: 40px;
		margin-inline-start: 100% - $nl-header-desktop-width;
		width: $nl-header-desktop-width;
	}

	.newsletter-library__subheader,
	.newsletter-library__slider,
	.newsletter-library__controls {
		padding-inline-start: 148px;
	}

	.newsletter-library__letter-image {
		width: 437px;
		height: 300px;
	}
}
