$locations-padding-y: 32px; // set with var
$locations-padding-x: $container-mobile-padding;

// Tokens for Countries - Desktop

$locations-countries-padding-y: 120px;
$locations-countries-padding-x: 32px;

.locations__countries-wrapper {
	padding: $locations-padding-y $locations-padding-x;
}

.continent + .continent {
	margin-top: 48px;
}

.countries-container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
}

.country-heading {
	margin-top: 16px;
}

.country-image {
	width: 100%;
	object-fit: cover;
	object-position: center;
	aspect-ratio: 2/1;
}

.country .clinic-card__facilities {
	margin-top: 8px;

	.clinic-card__facilities-item {
		margin-inline-end: 16px;
	}

	.clinic-card__facilities-item svg {
		font-size: 24px;
	}

	.clinic-card__facilities-item:first-child svg {
		color: $color-brand-primary;
		font-size: 22px;
	}
}

@include small {
	.continent {
		display: flex;
	}
	.continent-title {
		min-width: 120px;
	}
	.countries-container {
		margin-inline-start: 32px;
		flex-grow: 1;
	}
}

@include tablet {
	.countries-container {
		grid-template-columns: 1fr 1fr;
	}
}

@include laptop {
	$locations-padding-x: $container-desktop-padding;
	$locations-breadcrumb-height: 56px;

	.continent + .continent {
		margin-top: 80px;
	}

	.locations__countries-wrapper {
		padding: $locations-countries-padding-y $locations-countries-padding-x;
	}

	.continent-title {
		min-width: 220px;
	}
}

@include desktop {
	.continent-title {
		min-width: 320px;
	}
}
