$input-height: 48px !default;
$input-padding-horizontal: 16px;
$input-padding-vertical: 12px;
$input-line-height: 20px;

$input-border-color-disabled: $color-green-medium;
$input-border-color-error: $color-red-main;
$input-border-width: 1px;
$input-active-color: #fff;
$input-active-background: $color-brand-primary;
$input-hover-background: $color-green-light;
$input-disabled-border-color: $color-green-medium !default;
$input-with-icon-color: #fff !default;
$input-underline-suffix-color: $color-brand-primary !default;
$input-underline-prefix-color: $color-brand-secondary !default;

$input-calendar-icon-size: 20px !default;
$input-calendar-icon-height: $input-height;
$input-calendar-icon-width: $input-height;

$input-with-icon-before-background-color: $color-brand-primary !default;

%reset-input {
	background: none;
	border: 0;
	border-radius: 0;
	outline: 0; // Sorry
}

input,
select,
textarea {
	font-family: inherit;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;

	&::placeholder {
		opacity: 1;
		color: $input-placeholder-color;
	}
}

.input-label {
	display: block;
	margin-bottom: 8px;

	&__icon {
		display: inline-flex;
		align-items: center;
		margin-inline-start: 8px;
		font-size: 20px;
	}
}

.input {
	@extend %reset-input;
	width: 100%;
	color: $global-font-color;

	&.error {
		border-color: $color-red-main;
	}
}

.input--primary {
	height: $input-height;
	padding: 0 $input-padding-horizontal;
	border: $input-border-width solid $input-border-color;

	&:disabled {
		border-color: $input-disabled-border-color;
	}
}

.input-error {
	margin-top: 4px;
	color: $input-error-color;
	line-height: 1.5;
	word-wrap: break-word;
	margin-bottom: 10px;
}

.input-with-icon {
	position: relative;

	&::before {
		content: '';
		width: $input-calendar-icon-width;
		height: $input-calendar-icon-height;
		background-color: $input-with-icon-before-background-color;
		position: absolute;
		@include flip(right, 0);
		@include flip(left, initial);
		bottom: 0;
		pointer-events: none;
	}

	&.disabled::before {
		background-color: $input-disabled-border-color;
	}

	&.prefix-icon {
		input {
			padding-inline-start: $input-height;
			padding-inline-end: 20px;
		}
	}

	.input {
		padding-inline-end: $input-calendar-icon-width + $input-padding-horizontal;
	}

	.input-with-icon__suffix,
	.input-with-icon__prefix {
		color: $input-with-icon-color;
		position: absolute;
		font-size: $input-calendar-icon-size;
		top: 50%;
		transform: translateY(-50%);
	}

	.input-with-icon__suffix {
		@include flip(right, ($input-calendar-icon-width - $input-calendar-icon-size) / 2);
		@include flip(left, initial);
	}

	.input-with-icon__prefix {
		@include flip(left, ($input-calendar-icon-width - $input-calendar-icon-size) / 2);
		@include flip(right, initial);
	}

	.input-with-icon__icon {
		position: absolute;
		color: $color-orange-primary;
		font-size: 24px;
		top: 50%;
		transform: translatey(-50%);
	}

	&--left {
		.input {
			padding-inline-start: 54px;
		}

		.input-with-icon__icon {
			@include flip(left, 16px);
		}
	}

	button.input-with-icon {
		&__suffix,
		&__prefix {
			width: $input-calendar-icon-width;
			height: 100%;
			top: 0;
			transform: none;
		}

		&__suffix {
			@include flip(right, 0);
		}

		&__prefix {
			@include flip(left, 0);
		}
	}

	&--disabled {
		.input {
			border-color: $input-border-color-disabled;
		}

		&::before {
			background-color: $input-border-color-disabled;
		}
	}

	&--error {
		.input {
			border-color: $input-border-color-error;
		}
	}
}

.input--inline {
	padding: 0;
	border: none;
	font-weight: $font-weight-medium;
	text-decoration: underline;

	&::placeholder {
		font-weight: $font-weight-medium;
		color: $input-placeholder-color;
		text-decoration: underline;
	}
}

.input-underline {
	position: relative;

	&__suffix,
	&__prefix {
		@extend %reset-button;
		position: absolute;
		top: 50%;
		margin-top: -0.5em;
		font-size: 24px;
		line-height: 0;
	}

	&__suffix {
		@include flip(right, 0);
		color: $input-underline-suffix-color;
	}

	&__prefix {
		@include flip(left, 0);
		color: $input-underline-prefix-color;
	}

	input {
		padding: 8px 32px;
		width: 100%;
		border: none;
		border-bottom: 1px solid $color-orange-primary;
		border-radius: 0;

		&:focus {
			outline: none;
		}
		&::placeholder {
			color: $input-placeholder-color;
		}
	}
}

.input--textarea {
	padding: 12px 16px;
	resize: none;
	border: 1px solid $input-border-color;
	height: 128px;
	&--extendable {
		resize: vertical;
		min-height: 92px;
	}
}

//- Group inputs
.group-input--inline {
	display: flex;
	flex-direction: column;

	&:first-of-type {
		.group-input--remove {
			display: none;
		}
	}

	.group-input--item {
		position: relative;
		margin-top: 16px;
	}

	.group-input--remove {
		@extend %reset-button;

		position: absolute;
		bottom: 15px;
		right: -30px;
	}
}

@include below-tablet {
	.group-input--inline {
		.group-input--remove {
			@include flip(right, -22px);
		}
	}
}

@include laptop {
	.group-input--inline {
		flex-direction: row;

		> * + * {
			margin-inline-start: 16px;
		}

		.group-input--item {
			flex: 1;
		}
	}
}
