$clinic-hours-table-day-font-size: $font-weight-medium !default;
$clinic-hours-table-rows-border-color: $color-green-primary !default;
$clinic-hours-table-even-rows-color: $color-gray-light !default;

.clinic__hours-title {
	margin-bottom: 32px;
}

.clinic__table {
	width: 100%;
	border-collapse: collapse;
}

.clinic__hours-table--row {
	border-top: 1px solid rgba($clinic-hours-table-rows-border-color, 0.25);
	border-bottom: 1px solid rgba($clinic-hours-table-rows-border-color, 0.25);
}

.clinic__hours-table--row td {
	padding: 16px;
}

.clinic__hours-table--row td:first-child {
	font-weight: $clinic-hours-table-day-font-size;
}

.clinic__hours-table--row td + td {
	padding-inline-start: 0;
}

.clinic__hours-table--row:nth-child(even) {
	background-color: $clinic-hours-table-even-rows-color;
}

@include laptop {
	.clinic__hours-title {
		margin-bottom: 16px;
	}
}
