$popover-status-label-padding: 8px 16px !default;
$popover-status-label-color: $color-green-primary !default;
$popover-missing-color: $color-green-medium !default;
$popover-last-item-icon-color: $color-brand-secondary !default;

.booking-popover {
	display: none;
	position: absolute;
	min-width: 360px;
	max-width: 460px;
	background: #ffffff;
	box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16);
	@include z-index('booking-popover');

	&__missing {
		color: $popover-missing-color;
	}

	&__body {
		padding: 24px;
	}

	&__footer {
		// padding top is smaller because in modal footer buttons have margin-top: 12px;
		padding: 12px 24px 24px 24px;
	}

	&__footer {
		border-top: 1px solid $popover-footer-border-color;
	}

	&__header {
		margin-bottom: 32px;

		a {
			text-decoration: underline;
		}

		> * + * {
			margin-top: 8px;
		}
	}

	&__title-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		font-size: 24px;
		font-weight: $font-weight-medium;
	}

	&__close {
		@extend %reset-button;
		margin-inline-start: 24px;
		color: $popover-close-button-color;
		font-size: 24px;
	}

	&__thicc,
	&__list {
		font-weight: $font-weight-medium;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__list-item {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		font-weight: $font-weight-medium;

		.icon {
			margin-inline-end: 8px;
			color: $popover-last-item-icon-color;
			font-size: 24px;
			flex-shrink: 0;
		}

		.status-label {
			padding: $popover-status-label-padding;
			color: $popover-status-label-color;

			&.hide {
				display: none;
			}
		}
	}

	&__list-separator {
		margin: 0 8px;
	}
}

// Hidden doesn't work if `display` property is overwritten
[data-placeholder-optional][hidden] {
	display: none !important;
}
