.header-banner {
	background-color: $color-yellow-main;

	&__inner {
		padding: 16px;
		line-height: 24px;
	}

	&__text {
		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	&__close {
		@extend %reset-button;

		.icon {
			width: 24px;
			height: 24px;
		}
	}
}
