$separator-dot-margin: 4px !default;
$separator-dot-lg-margin: 8px;

.separator--dot {
	> * + *::before {
		content: '\2022';
		margin: 0 $separator-dot-margin;
	}
}

.separator--dot-large {
	> * + *::before {
		content: '\2022';
		margin: 0 $separator-dot-lg-margin;
	}
}

.separator--line {
	> * + *::before {
		content: '-';
		margin: 0 4px;
	}
}

.separator--line-large {
	> * + *::before {
		content: '-';
		margin: 0 8px;
	}
}
