#new-booking-platform-modal {
	.modal {
		&__container {
			@include laptop {
				padding-left: 80px;
				padding-right: 80px;
				max-width: 960px;
			}
		}

		&__footer {
			margin-top: 20px;
		}
	}
}
