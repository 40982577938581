$navigation-bg-color: #fff;
$navigation-spacing-vertical: 16px;
$navigation-link-margin-vertical: 20px;
$navigation-icon-size: 24px;

$navigation-mobile-width: 72px !default;
$navigation-mobile-height: 64px;
$navigation-mobile-logo-height: 40px !default;
$navigation-mobile-button-color: $color-brand-secondary !default;

$navigation-desktop-height: 80px !default;
$navigation-desktop-link-color-primary: $color-brand-primary !default;
$navigation-desktop-link-color-hover: $color-brand-secondary !default;
$navigation-desktop-active-link-border: $color-brand-secondary;
$navigation-desktop-link-color-secondary: $color-brand-secondary;
$navigation-desktop-link-color-secondary-hover: $color-brand-primary;

$navigation-mobile-border-color: $color-green-medium !default;
$navigation-mobile-link-line-height: 48px !default;
$navigation-mobile-link-color: #fff !default;
$navigation-mobile-link-color-hover: $color-brand-secondary !default;
$navigation-mobile-link-active-color: $color-orange-light !default;
$navigation-mobile-search-background: $color-orange-light !default;

$navigation-input-group-bg: $color-orange-light !default;

$navigation-search-button-bg: $color-orange-light !default;
$navigation-search-button-color: $color-brand-secondary !default;
$navigation-search-form-margin-inline-start: 64px !default;

$navigation-mobile-open-menu-background: $color-brand-primary !default;
$navigation-mobile-button-nav-top-before: -6px !default;
$navigation-mobile-button-nav-top-after: 6px !default;

.navigation {
	display: flex;
	align-items: center;
	min-height: $navigation-mobile-height;
	width: 100%;
	background-color: $navigation-bg-color;
}

.navigation__container {
	display: flex;
	align-items: center;
	.draft-back-to-clinic-container {
		overflow: hidden;
		.draft-back-to-clinic-button {
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.navigation__mobile-nav {
	display: flex;
	font-size: $navigation-icon-size;
}

.navigation__mobile-button {
	@extend %reset-button;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $navigation-mobile-width;
	height: $navigation-mobile-height;
	color: $navigation-mobile-button-color;
	font-size: $navigation-icon-size;
}

.navigation__content--mobile {
	display: none;
	padding: 24px;
	& > * + * {
		margin-top: 24px;
	}
	&__item {
		display: block;
		font-size: 24px;
		color: #ffffff;
	}
	.accordion__item {
		color: #ffffff;
		transition: color 2s ease-in-out;
		&-header {
			padding: 0;
			justify-content: flex-start;
			margin-bottom: 8px;
		}
		&-header:hover {
			color: #e86825;
			& .accordion__item-header-title {
				text-decoration: none;
			}
		}
		&-header-title {
			font-size: 24px;
		}

		&-panel {
			margin-inline-start: 30px;
			font-size: 20px;
			.accordion__item-description {
				padding: 0;
			}
		}
		.navigation__content--mobile__dropdown__link {
			display: block;
			color: #ffffff;
			margin-top: 16px;
		}

		.navigation__content--mobile__dropdown__link:hover {
			color: #e86825;
			cursor: pointer;
		}
	}
	.accordion__item.active .accordion__item-header-icon {
		transform: rotate(90deg);
	}
}

.navigation__content--desktop {
	display: none;
	&__dropdown {
		display: none;
	}
}

.navigation__links,
.navigation__search-form {
	display: none;
}

.navigation__logo {
	flex: 0 0 auto;

	img {
		height: $navigation-mobile-logo-height;
	}
}

.navigation--open {
	.navigation__logo {
		flex: 0 1 auto;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.navigation__content--mobile {
		display: block;
	}
}

.navigation__main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 0 0 auto;
	@include padding(left, $container-mobile-padding);
	width: 100%;

	@include below-laptop {
		background: #fff;
	}
}

.navigation--simple {
	padding: 0 $container-mobile-padding;

	.navigation__clinic {
		padding: 8px 0;
		margin-inline-start: 24px;
		padding-inline-start: 24px;
		@include flip(border-left, 1px solid $color-green-light);

		.icon {
			font-size: $navigation-icon-size;
		}
	}
}

.navigation__search-button {
	@extend %reset-button;
}

.navigation__mobile-item--when-open {
	display: none;
}

.navigation__links,
.navigation__search-form {
	padding: $container-mobile-padding;
}

.navigation__link,
.navigation__dropdown-link {
	background: none;
	border: 0;
	color: $navigation-mobile-link-color;
	line-height: $navigation-mobile-link-line-height;
	cursor: pointer;
}

.navigation__mobile-button--nav,
.navigation__mobile-button--search {
	position: relative;

	&::before,
	&::after {
		content: '';
		display: block;
		background-color: $navigation-mobile-button-color;
		height: 2px;
		width: 18px;
		position: absolute;
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: all 0.4s ease;
	}

	&::before {
		top: $navigation-mobile-button-nav-top-before;
	}
	&::after {
		top: $navigation-mobile-button-nav-top-after;
	}
}

.navigation__mobile-button--search {
	&::before,
	&::after {
		top: 0;
		opacity: 0;
	}
	&::before {
		transform: rotate(-45deg);
	}
	&::after {
		transform: rotate(45deg);
	}
}

// Mobile Navigation is open
.navigation--open,
.navigation--search-open {
	height: 100vh;
	overflow-y: auto;

	.navigation__mobile-item--when-open {
		display: flex;
		width: 100%;
		align-items: center;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.navigation__mobile-item--when-closed {
		display: none;
	}

	.navigation__container {
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		height: 100%;
	}
	.navigation__links {
		padding-top: 0;
	}
	.my-bookings-nav-link {
		line-height: 32px;
	}
}

.navigation__mobile-button--nav.active {
	&::before,
	&::after {
		top: 0;
	}
	&::before {
		transform: rotate(-45deg);
	}
	&::after {
		transform: rotate(45deg);
	}
}

// When nav is opened
.navigation--open {
	.visible--search {
		display: none;
	}

	.navigation__mobile-button--nav {
		&::before,
		&::after {
			top: 0;
		}
		&::before {
			transform: rotate(-45deg);
		}
		&::after {
			transform: rotate(45deg);
		}
	}

	.navigation__mobile-button--nav {
		background-color: $navigation-mobile-open-menu-background;
	}

	.navigation__container {
		background: $navigation-mobile-open-menu-background;
	}

	.navigation__links {
		display: flex;
		flex-direction: column;
	}
	.logged-user-email {
		font-size: 20px;
		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.2;
		}
	}
	.navigation__dropdown-link {
		font-size: 24px;
	}
}

.navigation__link--large {
	font-size: 24px;
}

.navigation--search-open__visible {
	display: none;
}

// When search is opened
.navigation--search-open {
	.visible--navigation {
		display: none;
	}

	.navigation--search-open__visible {
		display: block;
	}

	.navigation__mobile-button--search {
		background-color: $navigation-mobile-search-background;

		.icon {
			opacity: 0;
		}
		&::before,
		&::after {
			opacity: 1;
		}
	}

	.navigation__search-form {
		background-color: $navigation-mobile-search-background;
		display: block;
	}
}

.navigation__container {
	justify-content: space-between;
	padding: 0;
}

.navigation__input-group--mobile {
	@extend .input-group--prefix;
	@extend .input-group--suffix;
	@extend .input-group--underline;
}

.navigation__input-group-calendars {
	margin-top: 24px;
}

.clinic__search-button {
	position: absolute;
	bottom: 0;
	left: 0;
}

.navigation__desktop-item {
	display: none !important;
}

.navigation__mobile-lang-item {
	display: none;
}

.navigation--open {
	.navigation__mobile-lang-item {
		display: block;
		color: $color-brand-primary;
	}
}

.navigation__divide {
	margin-inline-start: 24px;
	padding-inline-start: 24px;
	@include flip(border-left, 1px solid $color-green-light);
}

@include below-laptop {
	.navigation__link + .navigation__dropdown {
		border-top: 1px solid $navigation-mobile-border-color;
	}

	.navigation__dropdown {
		margin-top: 12px;
		padding-top: 12px;

		.dropdown__trigger,
		.dropdown__indicator {
			display: none;
		}

		.dropdown-menu {
			display: block;
			position: static;
			background: none;
			border: 0;
			box-shadow: none;
		}

		.navigation__dropdown-link {
			color: $navigation-mobile-link-color;
			padding: 0;

			&:hover {
				color: $navigation-mobile-link-color-hover;
			}
		}
	}

	.navigation__link.visible--navigation.active {
		color: $navigation-mobile-link-active-color;
		text-decoration: underline;
		text-underline-offset: 2px;
	}
}

.navigation__search-form--lite-picker {
	padding-bottom: 100px;
}

@include laptop {
	.navigation__content--mobile {
		display: none;
	}

	.navigation__desktop-item {
		display: flex !important;
	}

	.navigation__container {
		padding-left: $container-desktop-padding;
		padding-right: $container-desktop-padding;
		.navigation__desktop-item {
			overflow: hidden;
			.icon {
				flex-shrink: 0;
			}
			span {
				line-height: normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.navigation {
		min-height: $navigation-desktop-height;
	}

	.navigation__main {
		width: auto;
		@include padding(left, 0);
	}

	.navigation__content--desktop {
		display: flex;
		align-self: stretch;
		flex-wrap: wrap;
		font-size: 16px;
		margin-inline-start: 46px;
		margin-bottom: 4px;
		&__item {
			margin-inline-start: 18px;
			position: relative;
			display: flex;
			align-items: center;
			&:hover:after,
			&.active:after {
				width: 100%;
			}
			&::after {
				position: absolute;
				bottom: 0;
				content: '';
				display: block;
				width: 0;
				height: 4px;
				background-color: #e86825;
				transition: width 0.4s ease-in-out;
			}
		}
		&__dropdown {
			display: grid;
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.4s ease-in-out;
			width: 100%;
			grid-template-columns: repeat(12, 1fr);
			gap: 32px;
			position: absolute;
			min-height: 560px;
			top: 100%;
			left: 0;
			padding: 40px 32px;
			width: 100%;
			background-color: #1c5335;
			color: #ffffff;
			&__links {
				grid-column: span 3;
				.navigation__content--desktop__dropdown__link {
					display: block;
					color: #ffffff;
					font-size: 20px;
					&:hover {
						cursor: pointer;
						color: #ffe0d0;
					}
				}
				.navigation__content--desktop__dropdown__link
					+ .navigation__content--desktop__dropdown__link {
					margin-top: 24px;
				}
			}
			&__cards {
				grid-column: 5 / span 8;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 32px;

				.navigation__content--desktop__dropdown__card {
					cursor: pointer;
					color: #ffffff;
					.dropdown-card-image {
						aspect-ratio: 1/1;
						object-fit: cover;
					}
					.dropdown-card-label {
						text-transform: uppercase;
						letter-spacing: 2.4px;
						margin-top: 16px;
						font-size: 12px;
						color: #ffe0d0;
					}
					.dropdown-card-description {
						line-height: 24px;
					}
				}
			}
		}
		&__dropdown.active {
			visibility: visible;
			opacity: 1;
		}
	}

	.navigation__mobile-item {
		display: none !important;
	}

	.navigation__search-form {
		display: block;
		margin-bottom: 0;
		margin-inline-start: $navigation-search-form-margin-inline-start;
		padding: 0;
	}

	.navigation__links {
		display: flex;
		align-items: center;
		margin-inline-start: 64px;
		padding: 0;
		@include spacing(left, auto);
	}

	.navigation__link {
		display: inline-flex;
		align-items: center;
		height: $navigation-desktop-height;
		color: $navigation-desktop-link-color-primary;
		margin-inline-start: $navigation-link-margin-vertical;
		border-bottom: 4px solid transparent;
		line-height: 1;

		&.active {
			border-bottom-color: $navigation-desktop-active-link-border;
		}

		&:hover {
			color: $navigation-desktop-link-color-hover;
		}
	}

	.navigation__link--secondary {
		color: $navigation-desktop-link-color-secondary;

		&:hover {
			color: $navigation-desktop-link-color-secondary-hover;
		}
	}

	.navigation__dropdown-link {
		color: $navigation-desktop-link-color-primary;
	}

	.navigation__link--large {
		font-size: 16px;
	}

	.navigation__search {
		display: flex;

		> * + * {
			margin-left: 1px;
		}
	}

	.navigation__search .input-group__icon {
		display: flex;
		align-items: center;

		font-size: $navigation-icon-size;
		color: $color-brand-secondary;
	}

	.navigation__input-group--desktop {
		height: 100%;
		padding-inline-start: $navigation-icon-size + 16px; // size + margin
		background-color: $navigation-input-group-bg;

		.input-group__input-prefix {
			@include flip(left, 16px);
		}

		.input-group__input-suffix {
			display: none;
		}

		.input-group__input {
			padding: 14px;
		}
	}

	.navigation__input-group-calendars {
		margin-top: 0;
		padding-inline-end: 0;
	}

	.navigation__search-button {
		line-height: 1;
		padding: 14px 16px;
		background-color: $navigation-search-button-bg;
		color: $navigation-search-button-color;
		font-size: $navigation-icon-size;
	}

	.navigation__dropdown {
		display: flex;
		align-items: center;
	}

	//lite picker
	.navigation__search-form--lite-picker {
		padding-bottom: 0px;
		.litepicker {
			top: 64px !important;
		}
	}
}
