$dropdown-cl-toggle-icon-color: $color-brand-secondary;

.dropdown-cl__btn-toggle {
	@extend .button--reset;
	@extend .button--secondary;
	min-height: 40px;
	padding: 0 16px;
}

.dropdown-cl__btn-toggle {
	.icon {
		color: $dropdown-cl-toggle-icon-color;
		transform: rotate(0deg);
		transition: transform 0.4s ease;
	}
}

.dropdown-cl__btn-toggle.active {
	.icon {
		transform: rotate(180deg);
	}
}

.dropdown-cl__btn-toggle.selected {
	@extend .button--secondary-active;
}

// Wrapping `dropdown__item` so `close` on `dropdown__item` can be prevented
// because `item` is `checkbox`.
.dropdown-cl__item {
	@extend .dropdown__item;
}

.dropdown-cl__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-top: 1px solid $color-green-light;
}
