//design tokens
$footer-color: $color-brand-primary;
$footer-text-color: #ffffff;
$footer-title-color: $color-orange-light;
$footer-links-hover: $color-orange-light;
$footer-x-margin: 30px;

.footer {
	background-color: $footer-color;
	padding: 24px 0;
	margin-top: auto;

	.footer__col > * + *,
	.footer__links {
		margin-top: 56px;
	}

	a {
		color: $footer-text-color;
		&:hover {
			color: $footer-links-hover;
		}
	}

	.footer__about-us {
		font-size: 24px;
	}

	.footer__about-us-col {
		display: flex;
		flex-direction: column;
	}

	.footer__about-us-col a + a,
	.footer__about-us-col + .footer__about-us-col {
		margin-top: 12px;
	}

	.footer__contact {
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.footer__contact a + a {
		margin-top: 20px;
	}

	.footer__contact svg {
		@include spacing(right, 15px);
	}

	.footer__contact span {
		text-decoration: underline;
		text-underline-position: under;
	}

	.footer__socials {
		max-width: 305px;
	}

	.footer__socials a + a {
		@include spacing(left, 39px);
	}

	.footer__socials .icon {
		font-size: 20px;
	}

	.footer__links {
		font-size: 12px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.footer__links a + a {
		margin-top: 12px;
	}

	.footer__title {
		color: $footer-title-color;
		font-size: 34px;
		font-weight: normal;
	}

	.footer__col-two {
		margin-top: 60px;
	}
}

.footer__navigation__links {
	font-size: 14px;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	a {
		display: flex;
		align-items: center;
		svg {
			margin-inline-start: 4px;
			font-size: 18px;
		}
	}
}

@include laptop {
	.footer {
		padding: 30px 0;
		margin-bottom: 0;

		// remove margin-top from elements for mobile layout
		.footer__col > * + *,
		.footer__links {
			margin-top: 0;
		}

		.footer__col-one > * + * {
			@include spacing(left, $footer-x-margin);
		}

		.footer__logo {
			flex-basis: 25%;
			align-self: flex-end;
		}

		.footer__about-us {
			font-size: 20px;
			flex-basis: 50%;
			display: flex;
		}

		.footer__about-us-col {
			flex-basis: 50%;
		}

		.footer__about-us-col + .footer__about-us-col {
			margin-top: 0;
			@include spacing(left, $footer-x-margin);
		}

		.footer__secondary-link {
			display: inline;
		}

		.footer__contact {
			font-size: 15px;
			flex-basis: 25%;
		}

		.footer__links a + a {
			margin-top: 0;
		}

		.footer__col-two {
			margin-top: 90px;
		}

		.footer__col {
			display: flex;
		}

		.footer__title {
			font-size: 48px;
			order: -1;
			flex-basis: 50%;
			align-self: center;
		}

		.footer__navigation {
			flex-basis: 50%;
			@include spacing(left, $footer-x-margin);
		}

		.footer__socials {
			display: flex;
			justify-content: right;
			max-width: none;
			margin-left: auto;
			width: 54%;
		}

		.footer__socials a + a {
			@include spacing(left, 30px);
		}
	}

	.footer__navigation__links {
		font-size: 12px;
		flex-direction: row;
		justify-content: right;
		gap: 16px;
		flex-wrap: wrap;
	}
}

@include large {
	.footer {
		.footer__links {
			flex-direction: row;
			justify-content: space-between;
			margin-top: 52px;
		}

		.footer__contact {
			font-size: 17px;
		}

		.footer__title {
			flex-basis: 25%;
		}

		.footer__navigation {
			flex-basis: calc(50% - #{$footer-x-margin / 2});
		}

		.footer__col-two {
			justify-content: space-between;
		}
	}
}
