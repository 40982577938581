$card-padding: 8px;
$card-font-size: 12px;
$card-sibling-spacing: 4px;

$card-background: $color-green-lighter !default;
$card-box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.16) !default;
$card-active-background: $color-brand-primary !default;
$card-active-color: $color-white;
$card-notice-background: $color-yellow-light !default;
$card-notice-active-background: $color-yellow-main !default;
$card-notice-active-color: $color-brand-primary !default;

$card-fade-color: $color-green-medium !default;

$card-gray-background: $color-gray-light !default;
$card-gray-color: $color-green-medium !default;

.card {
	padding: $card-padding;
	background-color: $card-background;
	font-size: $card-font-size;
	font-weight: $font-weight-medium;
	@include transition('box-shadow, background', 0.2s);

	&[role='button'] {
		cursor: pointer;
	}

	&:hover {
		box-shadow: $card-box-shadow;
	}

	&.active {
		background-color: $card-active-background;
		color: $card-active-color;
	}

	&.dropdown {
		min-height: 32px;
	}
}

.card__fade {
	color: $card-fade-color;
}

.card--notice {
	background-color: $card-notice-background;

	&.active {
		background-color: $card-notice-active-background;
		color: $card-notice-active-color;

		.card__fade {
			color: $card-notice-active-color;
		}
	}
}

.card + .card {
	margin-top: $card-sibling-spacing;
}

.card__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.card__icons {
	font-size: 16px;
	line-height: 0;
}

.card__info {
	display: flex;
	align-items: center;
	margin-top: 4px;
	@include rtl {
		flex-direction: column;
		align-items: flex-start;
		* + * {
			margin-top: 4px;
		}
	}

	> * + *::before {
		content: '\2022';
		margin: 0 4px;
		@include rtl {
			display: none;
		}
	}
}
/***************************************
    Variants
****************************************/

.card--plain {
	padding: 0;
	background-color: $card-gray-background;

	.card__header {
		padding: $card-padding;
	}
	.card__thicc {
		color: $card-gray-color;
	}

	&.active {
		.card__thicc {
			color: #fff;
		}
	}
}
