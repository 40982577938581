.home-hero {
	&__body {
		.bookdialysis-search-form {
			font-family: $global-font-family;

			.bookdialysis-search-form__autocomplete-form {
				.bookdialysis-search-form {
					&__autocomplete {
						&__input {
							&__icon .icon {
								color: $color-orange-primary;
							}

							&__input {
								color: $color-green-primary;

								@include laptop {
									padding: 25px 24px 25px 72px;
									font-size: 20px;
									line-height: 30px;
								}

								&::placeholder {
									color: $input-placeholder-color;
								}
							}

							input.bookdialysis-search-form__autocomplete__input__input {
								border: none;
							}
						}

						&__results__list__item {
							&:hover {
								background-color: $color-orange-light;
							}
							&__content {
								color: $color-orange-primary;
							}

							&__text {
								font-size: 20px;
							}
						}

						.autocomplete {
							&__item {
								&:hover {
									background-color: $color-orange-light;
								}

								&-main-info {
									color: $color-green-primary;
									font-size: 20px;
								}

								&-secondary-info {
									color: $color-orange-primary;
								}
							}
						}
					}

					&__datepicker {
						&__icon .icon {
							color: $color-orange-primary;
						}

						&__input {
							color: $color-green-primary;

							@include laptop {
								padding: 25px 14px 25px 72px;
								font-size: 20px;
								line-height: 30px;
							}

							&::placeholder {
								color: $input-placeholder-color;
							}
						}

						input.bookdialysis-search-form__datepicker__input {
							border: none;
						}
					}

					&__button {
						background-color: $color-orange-light;
						color: $color-orange-primary;

						@include laptop {
							padding: 20px 24px;
						}

						&__icon {
							&--desktop-icon {
								font-size: 40px;
							}
						}
					}
				}
			}

			.bookdialysis-search-form {
				&__datepicker-modal {
					&__footer {
						&__button {
							@extend .button, .button--primary;
						}
					}
				}
			}
		}
	}
}
