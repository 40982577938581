$pagination-item-width: 24px;
$pagination-item-margin: 12px;

$button-pagination-background: $button-secondary-background !default;
$button-pagination-color: $button-secondary-color !default;
$button-pagination-hover-background: $button-secondary-hover-background;
$button-pagination-hover-color: #fff;
$button-pagination-disable-color: $button-secondary-disable-color;
$button-pagination-disable-background: $button-secondary-disable-background;
$button-pagination-disable-color: $button-secondary-disable-color;

.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 600px;
	padding: 40px 32px;
}

.pagination__pages-container {
	display: inline-flex;
	overflow: hidden;
}

.pagination__page-item {
	display: flex;
	justify-content: center;
	width: $pagination-item-width;
	cursor: pointer;
}

.pagination__page-item.active {
	text-decoration: underline;
}

.pagination__page-item-wrapper + .pagination__page-item-wrapper {
	@include spacing(left, $pagination-item-margin);
}

.pagination__button {
	display: flex;
	font-size: 24px;
}

.pagination__page-item-separator {
	display: flex;
	justify-content: center;
	width: $pagination-item-width;
	@include spacing(left, $pagination-item-margin);
}

.pagination__pages-right {
	display: inline-flex;
	@include spacing(left, $pagination-item-margin);
}

.button--pagination {
	background-color: $button-pagination-background;
	color: $button-pagination-color;

	&:hover,
	&:focus-visible {
		border-color: $button-pagination-hover-background;
		background-color: $button-pagination-hover-background;
		color: $button-pagination-hover-color;
	}

	&[disabled] {
		border-color: $button-pagination-disable-color;
		background-color: $button-pagination-disable-background;
		color: $button-pagination-disable-color;
	}
}

@include laptop {
	$pagination-item-margin: 32px;
}

@include desktop {
	$pagination-item-margin: 48px;
}

@include below-tablet {
	$pagination-item-margin: 0;

	.pagination {
		padding: 20px 0;
	}
}
