$clinic-map-transport-item-padding: 20px 0 !default;
$clinic-map-number-font-size: 24px !default;
$clinic-map-number-margin-right: 8px !default;
$clinic-map-number-margin-left: 16px !default;
$clinic-map-info-bg-color: $color-orange-primary !default;
$clinic-map-info-color: $color-white !default;
$clinic-map-info-title-margin-bottom: 58px !default;
$clinic-map-info-padding: 48px 32px !default;
$clinic-map-icon-size: 40px !default;
$clinic-map-icon-margin-bottom: 24px !default;
$clinic-map-actions-link-color: $color-white !default;

.clinic {
	&__map-wrapper {
		margin-bottom: 24px;
	}

	&__map-input {
		margin: 32px 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__map-section {
		width: 100%;
		height: 454px;
	}

	&__map-origin {
		margin-top: 8px;
		width: 100%;
	}

	&__map-transport-info {
		margin-top: -16px;
		margin-bottom: 32px;
	}

	&__map-table--row {
		border-bottom: 1px solid rgba($color-green-primary, 0.25);

		td {
			font-size: 14px;
			padding: 20px 24px;

			&:first-child {
				text-align: start;

				.icon {
					font-size: 24px;
					margin-inline-end: 12px;
				}
			}

			&:last-child {
				text-align: right;

				@include rtl {
					text-align: left;
				}
			}
		}

		td + td {
			padding-inline-start: 0;
		}
	}

	&__map-number {
		font-size: $clinic-map-number-font-size;
		@include spacing(right, $clinic-map-number-margin-right);
	}

	&__map-unit + &__map-number {
		@include spacing(left, $clinic-map-number-margin-left);
	}

	&__map-info {
		padding: $clinic-map-info-padding;
		background-color: $clinic-map-info-bg-color;
		color: $clinic-map-info-color;
	}

	&__map-info-title {
		color: $clinic-map-info-color;
		margin-bottom: $clinic-map-info-title-margin-bottom;
	}

	&__map-actions {
		display: flex;
		justify-content: space-around;

		a {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $clinic-map-actions-link-color;
		}
		.icon {
			fill: $clinic-map-info-color;
			font-size: $clinic-map-icon-size;
			margin-bottom: $clinic-map-icon-margin-bottom;
		}

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 16px;

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $clinic-map-info-color;
		}
	}

	&__map-transport-info-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $clinic-map-transport-item-padding;
		border-bottom: 1px solid rgba($color-green-primary, 0.25);
	}

	&__map-transport-info-icon {
		.icon {
			font-size: 24px;
			margin-inline-end: 8px;
		}

		span + span {
			margin-left: 1em;
		}
	}
}

//Selector for tooltip that displays location information on map
.poi-info-window {
	padding: 8px;
}

@include laptop {
	.clinic {
		&__map-wrapper {
			margin-bottom: 72px;
		}

		&__map-actions {
			grid-gap: 32px;
		}
	}
}
