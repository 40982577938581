$gray-card-background-color: $color-gray-light !default;

.booking-section-container-sidebar {
	padding-bottom: 40px;
}

.gray-card {
	padding: 24px 24px 32px 24px;
	background-color: $gray-card-background-color;
}

.booking-clinic-preview {
	display: flex;
	padding-bottom: 24px;
}

.booking-clinic-preview__img-container {
	min-width: 64px;
}

.booking-clinic-address-container {
	@include spacing(left, 16px);
}

.booking-clinic-address {
	font-size: 12px;
}

.booking-clinic-price-container {
	padding-top: 24px;
	border-top: 1px solid $color-green-light;
	transition: opacity 0.2s ease-in-out;

	&.loading {
		opacity: 0.5;
		cursor: wait;
	}
}

.booking-clinic-price {
	font-size: 32px;
	margin: 0;
}

.booking-clinic-price-description {
	font-size: 16px;
}

.edit-button {
	display: inline-block;
}

.booking-clinic-price-label {
	display: inline-flex;
	align-items: center;
	color: $booking-clinic-price-color;

	padding: 4px 8px;
	font-size: 12px;
	background-color: $booking-clinic-price-label-background-color;
}

.booking-clinic-price-label .icon {
	@include spacing(left, 8px);
}

.booking-clinic-price-label-checked {
	color: $color-orange-primary;
	font-size: 20px;
	@include spacing(left, 8px);
}

.booking-clinic-price-label-container {
	display: flex;
	align-items: center;
	justify-content: space-between;

	& + & {
		margin-top: 16px;
	}
}

.booking-clinic-price-label-container .booking-clinic-price-label {
	color: $booking-clinic-price-label-color;
	background: none;
	font-size: 16px;
	padding: 0;
	margin: 0;
}

.booking-clinic-button-container {
	padding-top: 4px;
	border-top: 1px solid $color-green-light;
}

.booking-treatment__next-button {
	padding-left: 48px;
	padding-right: 48px;
}

.booking-card {
	padding: 32px 0;
}

.booking-card + .booking-card {
	border-top: 1px solid $color-green-light;
}

.booking-card-num {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	font-size: 20px;
	border: 1px solid $booking-card-number-border-color;
	border-radius: 50%;
	color: $booking-card-number-color;
	background-color: $booking-card-number-background-color;
}

.booking-card-disable-num {
	border: 1px solid $booking-card-number-disabled-background-color;
}

.booking-card-title {
	@include spacing(left, 16px);
}

.booking-card-disable-num,
.booking-card-disable-title {
	color: $booking-card-number-disabled-color;
}

.booking-card-title-container {
	display: flex;
}

.booking-card-data-container .booking-card-title-container {
	margin-bottom: 24px;
}

.booking-card-document {
	display: flex;
	align-items: center;
	padding: 20px 0;

	.booking-card-document-status-label,
	.booking-card-document-label,
	.booking-card-document-download-info {
		margin: 4px 0;
	}

	.booking-card-document-status-label {
		font-size: 12px;

		&--change {
			color: $schedule-label-change-color;
		}

		&--remove {
			color: $schedule-label-remove-color;
		}

		&--add {
			color: $schedule-label-add-color;
		}
	}

	.line-through {
		text-decoration: line-through;
		color: $schedule-label-line-through-color;
		font-weight: 400;
	}
}

.booking-edit-buttons {
	display: flex;
	flex-wrap: wrap;
	margin-top: 24px;
	padding-top: 24px;
	border-top: 1px solid $booking-card-document-border-color;

	* {
		margin-top: 8px;
	}
}

.booking-card-document + .booking-card-document {
	border-top: 1px solid $booking-card-document-border-color;
}

.booking-card-document:last-of-type {
	border-bottom: 1px solid $booking-card-document-border-color;
}

.booking-card-documents-title {
	margin-top: 32px;
	margin-bottom: 0;
}

.booking-card-document__info {
	margin: 0 0 4px 0;
}

.booking-card-document__info.error {
	color: $color-red-main;
}

.booking-card-document__link {
	&:hover {
		.booking-card-document-content {
			color: $color-green-primary;
		}
		.download-icon-container {
			background-color: $color-green-primary;
			color: white;
		}
	}
}

.download-icon-container {
	padding: 14px;
	border: 1px solid $color-green-primary;
	border-radius: 50%;
	@include spacing(right, 30px);
}

.download-icon-container--loading {
	animation: reappearing 1s;
	animation-iteration-count: infinite;
}

@keyframes reappearing {
	from {
		opacity: 0;
		background-color: $color-green-primary;
		color: #fff;
	}
	to {
		opacity: 1;
		background-color: inherit;
		color: $color-green-primary;
	}
}

.download-close-button {
	@include spacing(left, auto);
	cursor: pointer;
	border: none;
	background: none;
	color: inherit;
	transition: opacity 0.3s ease-in-out;
	&:hover {
		opacity: 0.8;
	}
}

.upload-document-button svg {
	font-size: 24px;
	@include spacing(right, 12px);
}

.booking-card-document .download-icon {
	font-size: 24px;
}

.display--none {
	display: none;
}

.booking-card-patient-details-section .checkbox {
	display: flex;
	margin-top: 16px;
}

.booking-card-patient-details-section {
	padding-bottom: 32px;
}

.booking-card-patient-details-section:last-child {
	padding-bottom: 0;
}

.booking-card-patient-details-section + .booking-card-patient-details-section {
	padding-top: 32px;
	border-top: 1px solid $color-green-light;
}

.patient-personal-data label,
.patient-home-clinic label {
	display: block;
	margin-top: 16px;
}

.patient-personal-data__radio-wrapper {
	display: flex;
	flex-direction: column;

	> label {
		display: flex;
	}

	> label + label {
		margin-top: 16px;
	}
}

.active .booking-card-title-container {
	flex-direction: column;
}

.active .booking-card-num {
	color: $color-white;
	background-color: $booking-card-number-background-active-color;
	margin-bottom: 16px;
}

.active .booking-card-title {
	@include spacing(left, 0);
}

.patient-home-clinic-diaverum-clinic {
	display: flex;
}

.patient-home-clinic-diaverum-clinic svg {
	@include spacing(right, 8px);
}

.medical-documents .booking-card-document:first-child {
	padding-top: 0;
}

.medical-documents .booking-card-document:last-child,
.booking-details .booking-card-document:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.split-section .booking-section-indent {
	margin-left: 0;
}

.treatment-day {
	font-weight: $font-weight-medium;
}

.booking-section__treatment-das {
	> * {
		margin-top: 32px;
	}
}

.booking-overview .split-section.patient-home-clinic,
.booking-overview .split-section.medical-documents {
	border-bottom: 1px solid $color-green-light;
}

.booking-accepted .split-section.medical-documents {
	border-bottom: 0;
}

.booking-overview .booking-clinic-button-container {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: none;
}

.booking-section-container-sidebar {
	display: flex;
	flex-direction: column;
}

.booking-section-container {
	/*order: 2;*/ // TODO: check with FE team
}

.booking-clinic-details {
	> * + * {
		margin-top: 24px;
	}

	&--laptop {
		order: 1;
	}
}

.booking-clinic__action-float {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	> * + * {
		margin-top: 24px;
	}
}

@include below-laptop {
	.booking-clinic-details--float {
		position: fixed;
		top: $site-header-height;
		left: 0;
		width: 100%;
		@include z-index('navigation');
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.visible {
			opacity: 1;
		}

		.booking-clinic-details-hide {
			display: none;
		}

		.booking-clinic-price-container {
			padding: 0;
			border: 0;
		}

		.booking-clinic-price-label--laptop {
			margin: 0;
		}

		.booking-clinic-price-label--laptop {
			margin-top: 8px;
		}

		.gray-card {
			margin: 0;
			padding: 16px 24px;
		}
	}

	.booking-clinic__action-float {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 20px 24px;
		background: #fff;
		border-top: 1px solid $color-green-light;
	}

	.booking-section-container-sidebar--draft {
		padding-bottom: 124px;
	}

	.booking-card__edit {
		margin-top: 24px;
	}

	.booking-request__notification-desktop {
		display: none;
	}
}

@include tablet {
	.booking-clinic-button-container {
		display: flex;
		align-items: center;
	}

	.booking-clinic-delete-button {
		@include spacing(left, 24px);
	}

	.patient-personal-data__radio-wrapper {
		flex-direction: row;

		> label {
			flex-direction: row;
		}

		> label + label {
			margin-top: 0;
			@include flip(margin-left, 8px);
		}
	}
}

@include laptop {
	.booking-section-container-sidebar {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		margin: 0 auto;
		padding-bottom: 124px;
	}

	.booking-section-container {
		flex-basis: 60%;
		/*order: 1;*/ // TODO: check with FE team
	}

	.booking-section-container--lg {
		flex-basis: 80%;
	}

	.booking-clinic-details--laptop {
		flex-basis: 40%;
		position: sticky;
		top: $site-header-height;
		order: 2;
	}

	.booking-clinic-details--mobile {
		display: none;
	}

	.booking-clinic-details--laptop {
		@include spacing(left, 120px);
	}

	.booking-clinic-price-title--laptop {
		font-size: 24px;
		margin-bottom: 16px;
	}

	.booking-clinic-price-label--laptop {
		margin-top: 16px;
		margin-bottom: 0;
	}

	.booking-card {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.booking-card-data-container {
		width: 90%;
		margin: 0;
	}

	.booking-card-num {
		width: 48px;
		height: 48px;
		font-size: 24px;
	}

	.booking-card-title,
	.active .booking-card-title {
		@include spacing(left, 68px);
	}

	.booking-section-indent {
		@include spacing(left, 116px);
	}

	.booking-card-data-container {
		.booking-section-indent {
			@include spacing(left, 116px);
		}
	}

	.active .booking-card-title-container {
		flex-direction: row;
	}

	.active .booking-card-num {
		margin-bottom: 0;
	}

	.booking-card-button {
		margin-top: 0;
		@include spacing(left, 16px);
	}

	.booking-clinic-button-container,
	.booking-overview .booking-clinic-button-container {
		display: none;
	}

	.booking-section__treatment-das {
		> * {
			margin-top: 40px;
		}
	}

	.booking-overview .split-section.medical-documents {
		border-bottom: none;
	}

	.booking-request__notification-mobile {
		display: none;
	}
}

@include between-tablet-laptop {
	.booking-clinic__action-float {
		flex-direction: row;

		> * {
			margin-top: 0;
			margin-inline-end: 15px;
		}
	}
}
