$calendar-item-hover-color: $color-brand-primary !default;
$item-padding: 12px;
$item-border-color: $color-green-light;
$calendar-item-hover-background: $color-gray-light !default;

.calendar__wrapper {
	margin-bottom: 64px;
	@include flip(border-left, 1px solid $item-border-color);
}

.calendar__header {
	display: flex;
}

.calendar__header-cell {
	padding: 24px 0;
	border-bottom: 1px solid $item-border-color;
}

.calendar__row {
	display: flex;
}

.calendar__cell {
	flex: 0 0 100/7 * 1%;
	min-width: 170px;
}

.calendar__item {
	padding: $item-padding;
	border-bottom: 1px solid $item-border-color;
	border-right: 1px solid $item-border-color;
	font-weight: $font-weight-medium;
	@include transition('background-color, color', 0.2s);

	&:hover {
		background-color: $calendar-item-hover-background;
		color: $calendar-item-hover-color;
	}
}

.calendar__item-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 6px;
}

.calendar__item-day {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 24px;
}

.calendar__bookings {
	margin: 0;
	padding: 0;
	list-style: none;
}

.calendar__bookings-item {
	display: flex;
	align-items: center;
	font-size: 12px;

	& + & {
		margin-top: 10px;
	}
}

.calendar__bookings-indicator {
	width: 8px;
	height: 8px;
	margin-right: 8px;
	border-radius: 50%;
}

.calendar__bookings-indicator--yellow {
	background-color: $calendar-indicator-yellow-color;
}
.calendar__bookings-indicator--orange {
	background-color: $calendar-indicator-orange-color;
}
.calendar__bookings-indicator--green {
	background-color: $calendar-indicator-green-color;
}

.calendar__bookings-count {
	@include spacing(left, auto);
}

// States

.calendar__item--disabled * {
	opacity: 0.8;
}

.calendar__item--active .calendar__item-day {
	width: 40px;
	height: 40px;
	margin-left: -6px;
	background-color: $calendar-active-day-background-color;
	color: #fff;
}

@include media-breakpoint-down(tablet) {
	.calendar {
		overflow-x: auto;
	}
}

@include media-breakpoint-up(tablet) {
	.calendar__header {
		position: sticky;
		top: $site-header-height;
		display: flex;
		background-color: #fff;
		@include z-index('sticky-header');
	}
}
