.home-stats {
	padding-top: 32px;
	padding-bottom: 32px;
}

.home-stats-title {
	padding-bottom: 32px;
}

.home-stats-content {
	display: flex;
	flex-direction: column;
}

.home-stats-card {
	padding-bottom: 40px;
	flex-basis: calc(50% - 8px);
}

.home-stats-card-num {
	color: $color-orange-primary;
}

.home-stats-card-description {
	padding-top: 16px;
	border-top: 1px solid $color-green-primary;
}

@include small {
	.home-stats-content {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.home-stats-card:nth-child(even) {
		@include flip(margin-left, 16px);
	}
}

@include laptop {
	.home-stats {
		padding-top: 64px;
		padding-bottom: 64px;
	}

	.home-stats-title {
		padding-bottom: 56px;
	}

	.home-stats-title,
	.home-stats-content {
		max-width: 1142px;
		margin: 0 auto;
	}

	.home-stats-content {
		justify-content: center;
		column-gap: 32px;
	}

	.home-stats-card:nth-child(even) {
		margin-left: 0;
	}

	.home-stats-card {
		flex-basis: calc(20% - 35px);
	}

	.home-stats-card-description {
		margin-top: 24px;
	}
}
