$arrow-button-size: 56px;
$slider-arrow-spacing: 42px;

$slider-height: 60vh;

.clinic__image-wrapper {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	margin-bottom: 24px;
}

.clinic__image-grid {
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	height: 220px;

	[data-micromodal-trigger] {
		cursor: pointer;
	}
}

.clinic__image {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.clinic__image-item {
	position: relative;

	&:first-child {
		grid-row: 1 / 3;
	}

	/**
		* CSS "if" magic
		* Using nth-child with first and last child to count the number of elements.
		* Change the layout based on the number of elements
	 */

	// One element
	&:first-child:last-child {
		grid-column: 1 / 3;
	}

	// Two elements
	&:nth-child(2):last-child {
		grid-row: 1 / 3;
	}
}

.clinic__image-more {
	@extend %reset-button;
	position: absolute;
	bottom: 24px;
	right: 24px;
	padding: 12px 16px;
	border-radius: 100vh;
	background-color: #fff;
	line-height: 1.5;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.clinic__image-modal {
	.modal__overlay {
		background-color: #fff;
	}

	.clinic__image-slider {
		position: relative;
		text-align: center; // Center images

		img {
			height: $slider-height;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}

	.clinic__image-slider-arrows {
		display: flex;
		justify-content: space-between;
		position: absolute;
		left: 0;
		right: 0;
	}
}

/**
	* Specific case for mobile.
 */
@include media-breakpoint-down(laptop) {
	.clinic__image-modal {
		.container {
			position: relative;
			height: 100%;
			padding: 0;
		}

		.modal__header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 2;
			padding-top: 12px;
			padding-bottom: 12px;
			background-color: #fff;
		}

		.clinic__image-slider {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-content: stretch;
			align-items: center;
		}

		.clinic__image-slider-inner {
			flex: 1;
			min-width: 0;
		}

		.clinic__image-slider-arrows,
		.modal__header {
			padding-left: 24px;
			padding-right: 24px;
		}

		.glide__counter,
		.clinic__image-slider-arrows {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 12px;
		}

		.clinic__image-slider-arrows {
			z-index: 2;
		}

		.glide__counter {
			bottom: 0;
			height: 56px + (2 * 12px);
			background-color: #fff;
			line-height: 56px + (2 * 12px);
		}
	}
}

@include laptop {
	.clinic__image-wrapper {
		width: 100%;
		position: relative;
		left: initial;
		right: initial;
		margin-left: 0;
		margin-right: 0;
		margin-inline-end: 32px;
		margin-bottom: 32px;
	}

	.clinic__image-grid {
		height: 550px;
	}

	.clinic__image-modal {
		.clinic__image-slider {
			padding: 0 ($arrow-button-size + $slider-arrow-spacing);
		}

		.clinic__image-slider-arrows {
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
