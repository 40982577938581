body {
	padding-top: $site-header-height;
}

.site-header {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: #fff;
	@include z-index('navigation');
}

@include laptop() {
	body {
		padding-top: 0;
	}
	.site-header {
		position: sticky;
	}
}
