@charset "UTF-8";

@import 'settings.global';

@import 'shared/shared';

@import 'components/components';

@import 'utilities/utilities';

@import 'plugins/plugins';

// Debug classed used for sanity check on centering
[debug] {
	position: relative;
	&::before,
	&::after {
		content: '';
		position: absolute;
	}
	&::before {
		top: 50%;
		right: 0;
		left: 0;
		height: 1px;
		background: red;
	}
	&::after {
		top: 0;
		bottom: 0;
		left: 50%;
		width: 1px;
		background: green;
	}
}

[wip] {
	position: relative;
	// opacity: 0.7;
	cursor: not-allowed;
	pointer-events: none;
	&::after {
		content: 'WIP';
		padding: 2px 4px;
		margin: 0 10px;
		border-radius: 3px;
		background: darkred;
		color: #fff;
		font-size: 10px;
	}
}
