$sidebar-width: 320px;

$sidebar-item-font-size: 20px !default;
$sidebar-padding-vertical: 24px !default;
$sidebar-padding-horizontal: 0;

$sidebar-item-padding-vertical: 16px;
$sidebar-item-padding-horizontal: 24px;

$sidebar-background: $color-gray-light !default;
$sidebar-item-color: $color-green-medium !default;
$sidebar-item-hover-background: $color-orange-light !default;
$sidebar-item-set-color: $color-brand-primary !default;
$sidebar-item-set-numbers-color: $color-brand-secondary !default;
$sidebar-item-active-color: $color-brand-primary !default;
$sidebar-item-active-border-color: $color-brand-secondary !default;
$sidebar-item-active-border-width: 4px;

.sidebar-container {
	margin-bottom: 120px;

	&__sidebar {
		padding: $sidebar-padding-vertical $sidebar-padding-horizontal;
		background-color: $sidebar-background;
		margin-bottom: 32px;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $sidebar-item-padding-vertical $sidebar-item-padding-horizontal;
		@include flip(border-left, $sidebar-item-active-border-width solid transparent);
		color: $sidebar-item-color;
		font-weight: 400;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: $sidebar-item-hover-background;
		}

		&.set {
			color: $sidebar-item-set-color;

			.sidebar-container__numbers {
				color: $sidebar-item-set-numbers-color;
			}
		}

		&.active {
			border-color: $sidebar-item-active-border-color;
			color: $sidebar-item-active-color;
		}
	}
}

@include laptop {
	.sidebar-container {
		display: flex;
		align-items: flex-start;

		&__sidebar {
			position: sticky;
			top: $site-header-height;
			min-width: $sidebar-width;
			margin-inline-end: 32px;
			margin-bottom: 0;
		}

		&__item {
			font-size: $sidebar-item-font-size;
		}
	}
}

@include desktop {
	.sidebar-container {
		&--flex--spread {
			justify-content: space-between;
		}
	}
}
