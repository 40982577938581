$input-error-border-color: $color-red-main;
$platform-success-flash-message-bg-color: $color-green !default;
$platform-success-flash-message-color: $color-green-primary !default;
$platform-alert-flash-message-bg-color: $color-yellow-light !default;
$platform-alert-flash-message-color: $color-orange-primary !default;

.auth__flash-message {
	&-container {
		display: none;
		opacity: 0;
		position: fixed;
		top: 0px;
		@include z-index('flash-message');
		width: 100%;

		&.active {
			display: block;
			opacity: 1;
		}
	}

	&-content {
		display: flex;
		justify-content: space-between;
		max-width: 672px;
		margin: 0 auto;
		padding: 24px;
	}

	&-button {
		background-color: transparent;
		border: none;
		color: inherit;
		cursor: pointer;
		@include spacing(left, 8px);
	}

	&-alert-message {
		background-color: $platform-alert-flash-message-bg-color;
		color: $platform-alert-flash-message-color;
	}

	&-success-message {
		background-color: $platform-success-flash-message-bg-color;
		color: $platform-success-flash-message-color;
	}
}

.auth__container {
	display: flex;
	justify-content: center;
	padding-top: 48px;
	padding-bottom: 88px;

	.icon {
		font-size: 80px;
	}

	.input-error {
		font-size: 12px;
	}
}

.registration-col {
	margin-top: 48px;
	margin-bottom: 40px;
	max-width: 437px;
}

.auth__description {
	margin-top: 16px;
}

.auth__label,
.auth__alternative-link {
	display: inline-block;
	margin-top: 32px;
}

.auth__checkbox {
	align-items: flex-start;
}

.auth__alternative-link {
	text-underline-offset: 2px;
}

.help-text-label {
	margin-top: 8px;
	color: $help-text-label-color;
}

.auth__alternative-link,
.auth__privacy-policy a {
	text-decoration: underline;
}

.image-col {
	background-color: $color-orange-light;

	img {
		display: block;
		width: 100%;
	}

	&__description-container {
		padding-top: 24px;
		padding-bottom: 88px;
	}
}

.input__text-help {
	color: $color-green-medium;
	font-size: 12px;
}

.new-booking-flow-button {
	min-width: 126px;
	justify-content: center;
}

@include laptop {
	.auth__flash-message-container {
		top: 32px;
	}

	.registration-container {
		display: flex;
	}

	.image-col {
		flex-basis: 50%;

		&__description-container {
			padding-top: 32px;
		}
	}
}

//error state
.input.error {
	border-color: $input-error-border-color;
}
