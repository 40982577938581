.page-header {
	display: flex;
	flex-direction: column;
}

@include media-breakpoint-up(tablet) {
	.page-header {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}
