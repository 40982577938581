$container-md-width: 1440px;
$container-module-width: 1088px;
$container-sm-width: 790px;
$container-xs-width: 438px;

@mixin create-container($width, $padding) {
	padding-left: $padding;
	padding-right: $padding;
	max-width: $width + (2 * $padding);
}

.container {
	margin: 0 auto;
	width: 100%;
	@include create-container($container-md-width, $container-mobile-padding);

	&--sm {
		@include create-container($container-sm-width, $container-mobile-padding);
	}

	&--md {
		@include create-container(1140px, $container-mobile-padding);
	}

	&--xs {
		@include create-container($container-xs-width, $container-mobile-padding);
	}

	&--module {
		@include create-container($container-module-width, $container-mobile-padding);
	}

	&--plain {
		padding: 0;
	}
}

.container--left {
	margin: 0;
}

@include tablet {
	.container {
		@include create-container($container-md-width, $container-desktop-padding);

		&--sm {
			@include create-container($container-sm-width, $container-desktop-padding);
		}

		&--md {
			@include create-container(1140px, $container-desktop-padding);
		}

		&--xs {
			@include create-container($container-xs-width, $container-desktop-padding);
		}

		&--module {
			@include create-container($container-module-width, $container-desktop-padding);
		}

		&--plain {
			padding: 0;
		}
	}
}

@include laptop {
	.container--indent {
		padding-inline-start: 150px !important;
	}

	.container--indent-lg {
		// 	this percenteg will be 176px on 1440px resolution
		padding-inline-start: 12.35% !important;
	}
}

@include large {
	.container--module {
		@include create-container($container-module-width, 64px);
	}

	.container--indent-lg {
		// 	this percenteg will be 176px on 1440px resolution
		padding-inline-start: 176px !important;
	}
}

// start of refactoring containers
//-------------------------------------------------------------------
$container-np-module-width: 1088px;
$container-np-width: 1440px;
$container-np-md-width: 1140px;
$container-np-sm-width: 790px;
$container-np-xs-width: 438px;

@mixin create-np-container($width, $padding, $padding-desktop, $padding-large) {
	margin-left: auto;
	margin-right: auto;
	padding-left: $padding;
	padding-right: $padding;
	max-width: $width + (2 * $padding);
	width: 100%;

	@include tablet {
		padding-left: $padding-desktop;
		padding-right: $padding-desktop;
		max-width: $width + (2 * $padding-desktop);
	}

	@include large {
		padding-left: $padding-large;
		padding-right: $padding-large;
		max-width: $width + (2 * $padding-large);
	}
}

.container-np {
	@include create-np-container(
		$container-np-width,
		$container-mobile-padding,
		$container-desktop-padding,
		$container-large-padding
	);

	&--md {
		@include create-np-container(
			$container-np-md-width,
			$container-mobile-padding,
			$container-desktop-padding,
			$container-large-padding
		);
	}

	&--sm {
		@include create-np-container(
			$container-np-sm-width,
			$container-mobile-padding,
			$container-desktop-padding,
			$container-large-padding
		);
	}

	&--xs {
		@include create-np-container(
			$container-np-xs-width,
			$container-mobile-padding,
			$container-desktop-padding,
			$container-large-padding
		);
	}

	&--module {
		@include create-np-container(
			$container-np-module-width,
			$container-mobile-padding,
			$container-desktop-padding,
			$container-large-padding
		);
	}

	&--plain {
		padding: 0;
	}
}

.container-section-np--xxs {
	max-width: 438px;
	width: 100%;
}

.container-section-np--xs {
	max-width: 672px;
	width: 100%;
}

.container-section-np--sm {
	max-width: 790px;
	width: 100%;
}

.container-section-np--md {
	max-width: 907px;
	width: 100%;
}
