$clinic-card-padding: $container-mobile-padding;
$clinic-card-img-size: 200px;
$clinic-card-img-width--mobile: 100%;
$clinic-card-img-height--mobile: 160px !default;
$clinic-card-address-separator: inherit !default;
$clinic-card-facilities-icon-color: $color-brand-secondary !default;
$clinic-card-container-top-border-color: $color-green-light !default;

.clinic-card {
	padding: $clinic-card-padding 0;

	&-container {
		& + & {
			border-top: 1px solid $clinic-card-container-top-border-color;
		}
	}
	&__img {
		width: $clinic-card-img-width--mobile;
		height: $clinic-card-img-height--mobile;
		object-fit: cover;
	}

	&__details-container {
		margin-top: 16px;

		.clinic-card-title-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		.clinic-card__facilities {
			margin-top: 12px; // 16px - 4px

			> * {
				margin-top: 4px;
			}
		}
	}

	&__details > * + * {
		margin-top: 8px;
	}

	&__details-info {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		line-height: 20px;
	}

	&__details-treatment {
		margin-top: 16px;
		line-height: 20px;
		font-weight: $font-weight-medium;

		&-title {
			font-weight: 400;
			margin-bottom: 0;
		}

		&-item {
			margin-top: 4px;
			margin-bottom: 0;
		}
	}

	&__facilities {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.icon {
			color: $clinic-card-facilities-icon-color;
		}
	}

	&__facilities-item {
		display: flex;
		align-items: center;
		margin-right: 8px;

		font-size: 12px;
		font-weight: 400;
		line-height: 20px;

		.icon {
			font-size: 20px;
		}
	}

	&__info-cta {
		@extend .button;
		@extend .button--underline;
		@extend .button--small;
		margin-bottom: -3px; // underlinePadding + border = 2px + 1px
	}

	&-small-list {
		> .clinic-card-small {
			border-top: 1px solid $clinic-card-small-border-top-color;
		}

		> .clinic-card-small:last-child {
			border-top: 1px solid $clinic-card-small-border-top-color;
		}
	}

	&-small__content > div:first-child {
		max-width: 630px;
	}

	// Not a variation, a separate design.
	&-small {
		display: flex;
		align-items: center;
		padding: 32px 0;

		.clinic-card-small__image {
			flex-shrink: 0;
			width: 64px;
			height: 64px;
			margin-inline-end: 16px;
			object-fit: cover;
		}

		.clinic-card-small__actions {
			margin-top: 8px;
		}
	}
}

@include tablet() {
	.clinic-card {
		display: flex;
		flex-direction: row;

		.clinic-card__details-container {
			flex-grow: 1;
		}

		&__img-wrapper {
			flex: 0 0 auto;
			@include spacing(right, 32px);
		}

		&__details-container {
			margin-top: 0;
		}

		&__img {
			width: $clinic-card-img-size;
			height: $clinic-card-img-size;
		}
	}
	.clinic-card-small {
		&__content {
			flex: 1 0 auto;
			display: flex;
			align-items: center;
			flex-flow: wrap;
		}

		&__actions {
			margin-top: 0;
			margin-left: auto;
		}
	}
}

@include laptop {
	.clinic-card {
		&__details-info {
			display: block;

			* + *:before {
				content: '\2022';
				color: $clinic-card-address-separator;
				margin: 0 8px;
			}
		}

		&__details-treatment {
			display: block;
			margin-top: 16px;

			&-title {
				font-size: 12px;
				margin: 0 0 4px 0;
			}
		}
	}
}

@include desktop {
	.clinic-card {
		display: flex;
		flex-direction: row;

		&__img-wrapper {
			flex: 0 0 auto;
			@include spacing(right, 32px);
		}

		&__img {
			width: $clinic-card-img-size;
			height: $clinic-card-img-size;
		}

		&__details-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-top: 0;

			.clinic-card__facilities {
				margin-top: 20px;
			}
		}

		&__details-info-container {
			display: flex;
			justify-content: space-between;

			.status-label {
				margin-top: 8px;
				white-space: nowrap;
				align-self: flex-start;
				order: 2;
			}
		}

		&__details-info {
			margin-top: 8px;
		}

		&__facilities-item {
			@include spacing(right, 18px);
			flex-basis: auto;
			line-height: 16px;
			font-weight: $font-weight-medium;
		}
	}

	.clinic-bookings__title + .sidebar-container,
	.clinic-bookings__subtitle + .sidebar-container {
		margin-top: 64px;
	}

	.clinic-card__title .booking-request {
		align-self: flex-start;
		text-align: right;
	}
}
