.w-content-module {
	@extend .container--sm;
	margin-top: 120px;
	margin-bottom: 120px;

	a {
		cursor: pointer;
		text-decoration: underline;
		transition: color 0.2s ease-in-out;
	}

	@include tablet {
		@include spacing(left, 33%);
	}

	li + li {
		margin-top: 8px;
	}

	ul,
	ol {
		@include padding(left, 32px);
	}

	.roman-numbers {
		list-style-type: upper-roman;
	}

	.alpha-letters {
		list-style-type: lower-alpha;
	}

	.text-large {
		font-size: 24px;
		line-height: 1.3;
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}

@include below-tablet {
	.smaller-margins {
		margin-top: 80px;
		margin-bottom: 80px;
	}
}

.content-module__section + .content-module__section {
	margin-top: 64px;
}

.content-module__footnote {
	font-size: 12px;
}

.browsers-links {
	align-items: flex-start;
}

.browsers-links a + a {
	margin-top: 8px;
}
