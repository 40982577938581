.text-start {
	text-align: start !important;
}

.text-center {
	text-align: center !important;
}

.text-end {
	text-align: end !important;
}
