.bookdialysis-search-results {
	font-family: $global-font-family;

	.bookdialysis-search-results__header--desktop__button__counter {
		color: $color-brand-primary !important;
	}

	&--search-open-search-toggle {
		background-color: $color-orange-light !important;
		color: $color-brand-primary !important;

		svg {
			color: $color-brand-secondary;
			font-size: 24px;
		}
	}

	.clinic-card {
		.clinic-card__details {
			.heading-2 a {
				color: $color-brand-primary;

				&:hover {
					color: $color-brand-secondary;
				}
			}
		}

		.clinic-card__facilities-item {
			span {
				color: $color-brand-primary;
				font-weight: 400;
			}
			svg {
				color: $color-brand-secondary;
				font-size: 24px;
			}
		}

		.clinic-card__details-treatment {
			a {
				background-color: $color-brand-secondary;
				border-radius: 30px;
				font-size: 16px;
				font-weight: 500;
				padding: 0 24px;

				svg {
					font-size: 20px;
					margin-left: 16px;
				}
			}

			&-items {
				display: flex;
			}

			&-item {
				display: inline-block;

				&--separator:after {
					content: '';
					border: 1px solid $color-green-light;
					margin: 0 8px;
				}
			}
		}

		.clinic-score__container {
			.clinic-score__grade {
				color: $color-brand-secondary;
				font-weight: 400;
			}
			.clinic-score__review-count {
				color: $color-brand-primary;
				font-weight: 400;
			}
			.clinic-score__number {
				background-color: $color-brand-secondary;
			}
		}
	}

	.bookdialysis-search-results__body__info-container {
		.bookdialysis-search-results__body__info-container__wrapper
			.bookdialysis-search-results__body__info-container__dropdown-button-label {
			color: $color-green-medium;
		}

		.bookdialysis-search-results__body__info-container__dropdown-button {
			color: $color-brand-primary;

			svg {
				color: $color-brand-secondary;
				font-size: 24px;
			}
		}

		.dropdown-menu {
			border-color: $color-brand-primary;
		}

		.dropdown__item {
			color: $color-brand-primary;
			&:hover {
				background-color: $color-brand-primary;
				color: $color-white;
			}
		}

		@include below-laptop {
			display: none;
		}
	}

	.bookdialysis-search-form__autocomplete__input__icon,
	.bookdialysis-search-form__datepicker__icon {
		svg {
			color: $color-brand-secondary !important;
			font-size: 24px !important;
		}
	}

	.bookdialysis-search-results-info {
		&__powered-by {
			color: $color-green-medium;
		}
	}

	.bookdialysis-search-results__header--desktop {
		@include laptop {
			display: flex;
			justify-content: space-between;
		}

		.filters-group {
			@include laptop {
				display: flex;
				align-items: center;
			}

			@include below-laptop {
				display: none;
			}

			.bookdialysis-search-results__header--desktop__button--filter {
				&-active {
					background-color: $color-brand-primary;
					border-color: $color-brand-primary;
				}

				&:hover {
					background-color: $color-brand-primary;
					border-color: $color-brand-primary;
				}
			}
		}
	}

	.bookdialysis-search-results__body-container {
		.bookdialysis-search-results__additional-filters {
			.bookdialysis-search-results__additional-filters__body {
				.modal__header {
					.modal__close {
						svg {
							color: $color-brand-secondary;
						}
					}
					h2 {
						font-size: 48px;
						font-weight: 500;
					}
				}

				.clinic-search-modal-buttons-group {
					button {
						background-color: $color-brand-secondary;
						border-radius: 30px;
						font-size: 16px;
						font-weight: 500;
						padding: 0 24px;

						&:hover {
							background-color: $color-brand-primary;
						}
					}

					a {
						color: $color-brand-primary;

						&:hover {
							color: $color-brand-secondary;
						}
					}
				}
			}

			.clinic-search__filters-section {
				h3 {
					font-weight: 500;
				}

				label {
					color: $color-brand-primary;

					input {
						border-color: $color-brand-primary;

						&:checked {
							background-color: $color-brand-primary;
							border-color: $color-brand-primary;
						}
					}
				}
			}
		}
	}

	.bookdialysis-search-results__header--mobile__clinic-search__filters-close__button {
		&::before,
		&::after {
			background-color: $color-brand-secondary;
		}
	}

	.bookdialysis-search-results__header--mobile__clinic-search__filters-header__actions--toggle {
		svg {
			color: $color-brand-secondary;
		}
		.bookdialysis-search-results__header--mobile__clinic-search__filters-header__actions--toggle__counter {
			right: 0px;
			top: -16px;
			background-color: $color-brand-primary !important;
		}
	}

	.bookdialysis-search-results__header--desktop__section {
		&:last-child {
			padding-left: 0;
			margin-left: 16px;
		}
	}

	.bookdialysis-search-results__header--mobile {
		.bookdialysis-search-results__header--mobile__clinic-search__filters-close {
			button {
				&:before,
				&:after {
					background-color: $color-brand-secondary;
				}
			}
		}
	}

	.bookdialysis-search-results__header--mobile__clinic-search__filters-header__actions {
		a,
		a:focus,
		a:hover {
			color: $color-brand-primary;
			border-color: $color-brand-primary;
		}
		.bookdialysis-search-results__header--mobile__clinic-search__filters-header__actions--save {
			border-radius: 0;
			color: $color-brand-secondary;
			background-color: #fff;
			padding: 0 0 2px;
			transition: border 0.2s ease-in-out;
		}
	}

	.bookdialysis-search-results__body__filters-body__section {
		+ .bookdialysis-search-results__body__filters-body__section {
			border-top: 1px solid $color-green-light;
		}
		h3 {
			color: $color-brand-secondary;
			font-size: 12px;
			letter-spacing: 3.2px;
			text-transform: uppercase;
			font-weight: 400;
		}
	}

	.bookdialysis-search-results__body__filters-body__section {
		.radio {
			color: $color-brand-primary;
			input {
				border-color: $color-brand-primary;
				width: 32px;
				height: 32px;

				&:checked {
					border-color: $color-brand-primary;
				}

				&::after {
					background-color: #e86825;
					border-radius: 50%;
					content: '';
					height: 12px;
					width: 12px;
				}
			}
		}

		.checkbox {
			color: $color-brand-primary;
			input {
				width: 32px;
				height: 32px;
				&:checked {
					background-color: #fff;
					border-color: $color-brand-primary;
				}
			}

			input::after {
				content: '';
				width: $checkbox-base-check-width;
				height: $checkbox-base-check-height;
				border: solid $checkbox-checkmark;
				border-width: 0 2px 2px 0;
				margin-top: -3px;
			}
		}
	}

	// Search form
	.bookdialysis-search-form {
		display: flex;

		@include below-tablet {
			flex-direction: column;
			width: 100%;
		}

		.bookdialysis-search-form {
			&__autocomplete {
				&__input {
					background-color: $color-orange-light;

					input.bookdialysis-search-form__autocomplete__input__input {
						border: none;
						font-size: 16px;
						line-height: 24px;
					}

					&__icon .icon {
						color: $color-orange-primary;
					}

					&__input {
						color: $color-green-primary;
						padding: 12px 12px 12px 50px !important;

						@include laptop {
							font-size: 20px;
							line-height: 30px;
						}

						&::placeholder {
							color: $input-placeholder-color;
						}
					}
				}

				&__results__list__item {
					&:hover {
						background-color: $color-orange-light;
					}
					&__content {
						color: $color-orange-primary;
					}

					&__text {
						font-size: 20px;
					}
				}

				.autocomplete {
					&__item {
						&:hover {
							background-color: $color-orange-light;
						}

						&-main-info {
							color: $color-green-primary;
							font-size: 20px;
						}

						&-secondary-info {
							color: $color-orange-primary;
						}
					}
				}
			}

			&__datepicker {
				background-color: $color-orange-light;
				margin-left: 1px;

				&__icon .icon {
					color: $color-orange-primary;
				}

				&__input {
					padding: 12px 12px 12px 50px !important;
					font-size: 16px;
					line-height: 24px;
					color: $color-green-primary;

					&::placeholder {
						color: $input-placeholder-color;
					}
				}

				input.bookdialysis-search-form__datepicker__input {
					border: none;
				}
			}

			&__button {
				background-color: $color-orange-light;
				color: $color-orange-primary;
				padding: 12px 14px;
				margin-left: 1px !important;

				&__icon {
					&--desktop-icon {
						font-size: 24px;
					}
				}
			}
		}

		.bookdialysis-search-form {
			&__datepicker-modal {
				&__footer {
					&__button {
						@extend .button, .button--primary;
					}
				}
			}
		}
	}

	&--search-open {
		.bookdialysis-search-results__header--mobile__clinic-search__filters-close__button {
			background-color: $color-orange-light !important;
		}

		.bookdialysis-search-form__autocomplete-form {
			background-color: $color-orange-light !important;
			padding: 32px 24px !important;
		}

		.bookdialysis-search-form {
			.bookdialysis-search-form {
				&__autocomplete {
					&__input {
						background-color: $color-orange-light;
						border-bottom: 1px solid $color-brand-secondary;
					}
				}
				&__autocomplete-form {
					.input-group__input-suffix {
						svg {
							color: $color-brand-secondary;
						}
					}
				}
				&__datepicker {
					border-bottom: 1px solid $color-brand-secondary;
				}
				&__button {
					height: 64px !important;
					background-color: $color-brand-secondary !important;
					color: #fff !important;
					margin-left: 0 !important;
				}
			}
		}
	}

	.redirect-modal {
		footer {
			display: flex;
			align-items: center;
			justify-content: center;

			.button {
				border-radius: 30px;
				&--primary {
					@extend .button--primary;
				}

				&--secondary {
					@extend .button--secondary;
				}
			}
		}
	}

	.bookdialysis-search-results-tooltip {
		color: $color-brand-primary;
		border-color: $color-brand-primary;

		&:hover {
			color: $color-brand-secondary;
			border-color: $color-brand-secondary;
		}
	}

	.pagination {
		a {
			color: $color-brand-primary;

			&:hover {
				color: $color-brand-secondary;
			}
		}
		.pagination__page-item.active {
			color: $color-brand-secondary;
		}
	}
}

@include between-tablet-laptop {
	.clinic-card {
		&__details-treatment-item {
			display: inline-block;

			&--separator:after {
				content: '';
				border: 1px solid $color-green-light;
				margin: 0 8px;
			}
		}
	}
}

@include below-tablet {
	.bookdialysis-search-results .bookdialysis-search-results__header--desktop .filters-group {
		display: none;
	}

	.bookdialysis-search-results--search-open-search-toggle {
		.location {
			white-space: nowrap;
			overflow: hidden;
		}
		.location + div {
			white-space: nowrap;
			overflow: hidden;
			padding-inline-start: 20px;
			position: relative;
			&:before {
				position: absolute;
				left: 10px;
				content: '';
				width: 2px;
				height: 100%;
				background-color: $color-brand-primary;
			}
		}
	}

	.clinic-card {
		.clinic-card__details-treatment {
			&-items {
				flex-direction: column;
			}
		}

		&__details-treatment-item {
			display: inline-block;

			&--separator:after {
				display: none;
			}
		}
	}
}

@include laptop {
	.bookdialysis-search-form__autocomplete,
	.bookdialysis-search-form__datepicker {
		flex-shrink: 1 !important;
	}
	.filters-group {
		margin-inline-start: 8px;
	}
}
