$spacers: (
	0px,
	2px,
	4px,
	6px,
	8px,
	12px,
	16px,
	24px,
	32px,
	36px,
	40px,
	48px,
	56px,
	60px,
	64px,
	80px,
	88px,
	auto
) !default;
$sides: (right, left, bottom, top);
$breakpoints: (laptop, desktop);

@each $side in $sides {
	@each $space in $spacers {
		.m#{str-slice($side,1,1)}-#{strip-unit($space)} {
			@include spacing($side, #{$space});
		}
	}
}
@each $breakpoint in $breakpoints {
	@each $side in $sides {
		@each $space in $spacers {
			.m#{str-slice($side,1,1)}-#{$breakpoint}-#{strip-unit($space)} {
				@include media-breakpoint-up($breakpoint) {
					@include spacing($side, #{$space});
				}
			}
		}
	}
}

.m-0 {
	margin: 0 !important;
}

.p-0 {
	padding: 0 !important;
}

.pl-0 {
	padding-inline-start: 0 !important;
}

.pr-0 {
	padding-inline-end: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}
