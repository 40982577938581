$clinic-staff-position-font-size: 12px !default;

.clinic__staff-title {
	margin-bottom: 32px;
}

.clinic__staff-member {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.clinic__staff-member + .clinic__staff-member {
	margin-top: 32px;
}
.clinic__staff-position {
	margin-top: 16px;
	margin-bottom: 8px;
	font-size: $clinic-staff-position-font-size;
}

.clinic__staff-name {
	padding: 2px 0;
	margin: 0;
}

.clinic__staff-description {
	margin-top: 8px;
	margin-bottom: 0;

	p {
		margin-bottom: 0;
	}
}

.clinic__staff-action {
	@include flip(margin-left, 5px);
	text-align: right;

	@include rtl {
		text-align: left;
	}

	.dropdown__trigger {
		@include flip(margin-right, 8px);
	}
}

@include laptop {
	.clinic__staff-title {
		margin-bottom: 24px;
	}

	.clinic__staff-member {
		flex-direction: row;
		align-items: center;
	}

	.clinic__staff-member + .clinic__staff-member {
		margin-top: 16px;
	}

	.clinic__staff-image {
		margin-inline-end: 16px;
		min-width: 200px;
		height: auto;
	}

	.clinic__staff-position {
		margin-top: 0;
	}
}

@include tablet {
	.clinic__staff-action {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
		@include flip(margin-left, 0);

		@include rtl {
			text-align: right;
		}

		.dropdown__trigger {
			margin: 0;
		}
	}

	.clinic__staff-edit {
		@include flip(margin-right, 14px);
	}
}
