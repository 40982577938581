$toggle-border-color: $color-brand-primary !default;
$toggle-active-background: $color-brand-primary !default;
$toggle-active-color: #fff;
$toggle-height: 40px;
$toggle-padding-horizontal: 16px;

.toggle {
	display: inline-flex;
	border: 1px solid $toggle-border-color;
}

.toggle__item {
	height: $toggle-height;
	padding: 0 $toggle-padding-horizontal;
	line-height: $toggle-height;
}

.toggle__item--active {
	background-color: $toggle-active-background;
	color: $toggle-active-color;
}
