@media print {
	@page {
		size: A4 landscape;
		margin: 0;
	}

	* {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}

	.site-header,
	.footer,
	.no-print {
		display: none !important;
	}
}
