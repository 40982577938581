$select-color: $color-green-primary !default;
$select-padding: 12px 16px !default;

.form-control + .form-control {
	margin-top: 16px;
}

.form-group__select-wrapper {
	position: relative;
	border: 1px solid $color-green-primary;
	margin-top: 8px;

	&:before {
		position: absolute;
		@include flip(right, 12px);
		top: 50%;
		width: 10px;
		height: 10px;
		pointer-events: none;
		content: '';
		// background-image: url('../../../../icons/base/icons/icon-arrow-orange.svg');
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='%23e86825' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.166 4.611L4.749.161a.578.578 0 01.8 0l.535.52a.538.538 0 01.001.775L2.453 5l3.632 3.544c.22.214.22.562-.001.776l-.535.52a.578.578 0 01-.8 0L.166 5.387a.538.538 0 010-.777z'/%3E%3C/svg%3E");
		transform: translateY(-50%) rotate(-90deg);
	}
}

.form-group__select {
	outline: none;
	border: none;
	width: 100%;
	padding: $select-padding;
	color: $select-color;
	-webkit-appearance: none;
	background: none;
}

.input {
	-webkit-appearance: none;
}
