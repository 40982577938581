.clinic__facilities-title {
	margin-bottom: 32px;
}

@include laptop {
	.clinic__facilities-title {
		margin-bottom: 16px;
	}
}

//This maybe should be global but for now i set for calender on clinic profile available treatments days
.litepicker--inline .input--inline {
	text-decoration: none;
}
