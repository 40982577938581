$input-group-prefix-suffix-size: 24px;
$input-group-prefix-suffix-margin: 12px;

.input-group {
	position: relative;
	display: flex;
	align-items: center;
}

.input-group--underline {
	padding-bottom: 16px;
	border-bottom: 1px solid $color-brand-secondary;
}

.input-group--prefix {
	@include flip(padding-left, $input-group-prefix-suffix-size + $input-group-prefix-suffix-margin);
}

.input-group--suffix {
	@include flip(padding-right, $input-group-prefix-suffix-size + $input-group-prefix-suffix-margin);
}

.input-group__input {
	background: none;
	border: 0;
}

.input-group__input-icon-absolute {
	position: absolute;
	display: flex;
	align-items: center;
	font-size: $input-group-prefix-suffix-size;
}

.input-group__input-prefix {
	@extend .input-group__input-icon-absolute;
	@include flip(left, 0);
}

.input-group__input-suffix {
	@extend .input-group__input-icon-absolute;
	@include flip(right, 0);
}
