$icon-text-spacing: 10px;

.icon {
	fill: currentColor;
	flex-shrink: 0;
	width: 1em;
	height: 1em;
	vertical-align: middle;
	overflow: hidden;
	pointer-events: none;
}

.icon-wrapper {
	display: inline-flex;
	align-items: center;
}

.has-icon {
	.icon {
		margin-inline-start: $icon-text-spacing;
	}
}

.icon.yellow {
	fill: #ffb31b;
	color: $color-brand-primary;
}

@include rtl {
	.icon--mirror {
		transform: rotate(-180deg);
	}
}
