[data-filter-list] {
	display: flex;
	flex-direction: column;
}
[data-filter-empty-state] {
	display: none;
}
.show-empty-state [data-filter-empty-state] {
	display: block;
}

.clinic-search-modal__container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.clinic-search-modal__search-header {
	margin-bottom: 32px;
}

@include media-breakpoint-down(laptop) {
	.clinic-search-modal__search-input {
		margin-bottom: 24px;
	}
}

@include media-breakpoint-up(laptop) {
	.clinic-search-modal__container {
		padding: 64px;
	}

	.clinic-search-modal__search-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 56px;
	}

	.clinic-search-modal__search-input {
		min-width: 400px;
	}
}
