$clinic-rate-bg-color: $color-orange-primary !default;

.clinic__basic-info span + span {
	margin-inline-start: 8px;
}

.clinic__contact-label {
	margin-bottom: 8px;
	font-size: 12px;
}

.clinic__contact,
.clinic__address {
	margin-top: 24px;
}

.clinic__phone {
	margin-bottom: 16px;
}

.clinic__phone a,
.clinic__mail a,
.clinic__address a {
	padding: 2px 0;
}

.clinic-share-button {
	margin-bottom: 24px;
	.icon {
		font-size: 24px;
	}
}

@include laptop {
	.clinic__contact {
		display: flex;
	}

	.clinic__contact,
	.clinic__address {
		margin-top: 32px;
	}

	.clinic__phone {
		margin-bottom: 0;
		margin-inline-end: 32px;
	}
}
