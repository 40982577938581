.width-100 {
	width: 100%;
}

.width-50 {
	width: 50%;
}

.height-100 {
	height: 100%;
}

.width-320 {
	width: 100%;
	max-width: 320px;
}
