$clinic-booking-title-margin-bottom: 8px !default;
$booking-list-card-image-margin-right: 32px !default;
$booking-list-card-padding: 32px 0 !default;
$booking-list-card-icon-color: $color-brand-secondary !default;

// clinic bookings headline component
.clinic-bookings {
	&__title {
		margin-bottom: $clinic-booking-title-margin-bottom;
	}

	&__title + .sidebar-container,
	&__subtitle + .sidebar-container {
		margin-top: 40px;
	}

	&__subtitle {
		margin: 0 0 40px 0;
	}
}

// clinic bookings card component (Bookings owerview page)
.bookings__list {
	width: 100%;
	padding-bottom: 40px;

	.booking-list-card {
		display: flex;
		flex-direction: row;
		padding: 24px 0;
		border-bottom: 1px solid $clinic-card-small-border-top-color;

		&:first-of-type {
			border-top: 1px solid $clinic-card-small-border-top-color;
		}
	}

	.booking-list-card__details-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.booking-list-card__img-wrapper {
		flex: 0 0 auto;
		margin-right: $booking-list-card-image-margin-right;
	}

	.booking-list-card__img {
		display: none;
	}

	.booking-list-card__request-date {
		margin-top: 4px;
		color: $clinic-booking-request-color;
	}

	.booking-list-card__details-info-container {
		.status-label {
			align-self: flex-start;
			order: 2;
			white-space: nowrap;
		}
	}

	.booking-list-card__details-info {
		display: flex;
		flex-direction: column;
	}

	.booking-list-card__facilities {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 16px;

		&-item {
			display: flex;
			align-items: center;
			font-weight: $font-weight-medium;
		}

		.icon {
			color: $booking-list-card-icon-color;
		}
	}
}

@include tablet {
	.booking-list-card__details-container {
		flex-grow: 1;
	}
}

@include laptop {
	.booking-content .sidebar-container__sidebar {
		flex-shrink: 0;
	}

	.bookings__list {
		.booking-list-card__details-info {
			display: block;

			* + *:before {
				content: '\2022';
				color: $clinic-card-address-separator;
				margin: 0 8px;
			}
		}

		.booking-list-card__img {
			display: block;
			width: 120px;
			height: 120px;
		}
	}
}

@include desktop {
	.clinic-bookings__title {
		margin-bottom: 16px;
	}

	.bookings__list {
		.booking-list-card {
			padding: $booking-list-card-padding;
		}

		.booking-list-card__title {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		.booking-list-card__facilities {
			flex-direction: row;
			margin-top: 0;

			&-item {
				margin-right: 20px;
			}
		}
	}
}
@include laptop {
	.booking-list-card__details-info-container {
		display: flex;
		justify-content: space-between;
	}
}
