$sizes: (
	sm: 20px,
	md: 24px,
) !default;

$font-sizes: (
	sm: 12px,
	md: 16px,
) !default;

.counter {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

@each $scale, $size in $sizes {
	.counter--#{$scale} {
		width: $size;
		height: $size;
		font-size: map-get($font-sizes, $scale);
	}
}
