$color-map: (
	brand-primary: $color-brand-primary,
	brand-secondary: $color-brand-secondary,
	green-primary: $color-green-primary,
	green-medium: $color-green-medium,
	green: $color-green,
	green-light: $color-green-light,
	green-lighter: $color-green-lighter,
	orange-dark: $color-orange-dark,
	orange-primary: $color-orange-primary,
	orange-medium: $color-orange-medium,
	orange-light: $color-orange-light,
	yellow-main: $color-yellow-main,
	yellow-light: $color-yellow-light,
	red-main: $color-red-main,
	red-light: $color-red-light,
	gray-main: $color-gray-main,
	gray-light: $color-gray-light,
	gray-brown-light: $color-gray-brown-light,
	white: $color-white,
) !default;

.label {
	padding: 4px 8px;
}

@each $color, $sys-color in $color-map {
	.label-bg-#{$color} {
		@extend .label;
		background-color: $sys-color;
	}

	.label-text-#{$color} {
		@extend .label;
		color: $sys-color;
	}
}
