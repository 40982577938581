$item-height: $input-height;
$item-padding-horizontal: $input-padding-horizontal;
$item-padding-vertical: 12px;
$select-search-input-disabled-bg-color: $color-gray-light !default;

.v-select-search {
	position: relative;
}

.v-select-search__wrapper {
	position: relative;
	display: flex;
	align-items: center;
	height: $item-height;
	padding: 0 $item-padding-horizontal;
	border: 1px solid $item-input-border-color;
}

.v-select-search__display {
	flex: 1;
	transition: opacity 0.2s ease-in-out;

	&.filtered {
		visibility: hidden;
	}
}

.v-select-search__input {
	@extend %reset-input;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $item-height;
	padding: 0 $item-padding-horizontal;

	&:read-only {
		cursor: pointer;
	}
}

.v-select-search.disabled {
	cursor: initial;
	background-color: $select-search-input-disabled-bg-color;
}

.v-select-search__menu {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;

	border: 1px solid $item-input-border-color;
	border-top: 0;

	@include z-index('dropdown');
}

.v-select-search__list {
	max-height: 240px;
	overflow-y: auto;
}

.v-select-search__item {
	padding: $item-padding-vertical $item-padding-horizontal;
	background-color: $item-background;
	cursor: pointer;
	@include transition('color, background-color', 0.2s);

	&.selected {
		color: $item-active-color;
		background-color: $item-active-background;
	}

	&.disabled {
		cursor: not-allowed;
		&:hover {
			background-color: $item-background;
		}
	}

	&:hover {
		background-color: $item-hover-background;
	}
}

.v-select-search__placeholder {
	color: $input-placeholder-color;
}

.v-select-search__arrow {
	font-size: 24px;
	color: $item-arrow-color;
	transition: transform 0.2s ease-in-out;
}

.v-select-search.open {
	.v-select-search__display {
		opacity: 0.7;
	}

	.v-select-search__arrow {
		transform: rotate(180deg);
	}
}
