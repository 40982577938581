$clinic-share-socials-title-font-size: 12px !default;
$clinic-share-socials-title-color: $color-orange-dark !default;
$clinic-share-socials-title-text-transform: uppercase !default;
$clinic-share-socials-title-letter-spacing: 2.4px !default;
$clinic-share-socials-spacing: 32px !default;
$clinic-share-socials-svg-font-size: 16px !default;
$clinic-share-modal-image-size: 64px !default;

.clinic-share-modal-image {
	width: $clinic-share-modal-image-size;
	height: $clinic-share-modal-image-size;
}

.clinic-share-socials-container {
	padding-top: 10px;
	padding-bottom: 20px;
}

.clinic-share-socials-title {
	font-size: $clinic-share-socials-title-font-size;
	color: $clinic-share-socials-title-color;
	text-transform: $clinic-share-socials-title-text-transform;
	letter-spacing: $clinic-share-socials-title-letter-spacing;
}

.clinic-share-socials * + * {
	margin-left: $clinic-share-socials-spacing;
}

.clinic-share-socials svg {
	font-size: $clinic-share-socials-svg-font-size;
}

.clinic-share-via-mail-content label {
	display: block;
}

@include below-laptop {
	.clinic-share-via-mail-content .heading-3 {
		font-size: 16px;
	}
}

@include laptop {
	.clinic-share-modal-container {
		max-width: 810px;
		padding: 100px 140px;
	}
}
