$popup-max-width: 290px;
$popup-cover-image-height: 180px;

.clinic-map-popup {
	max-width: $popup-max-width;
	background-color: #fff;
	font-family: $global-font-family;
}

.clinic-map-popup__image {
	width: 100%;
	height: $popup-cover-image-height;
	object-fit: cover;
}

.clinic-map-popup__body {
	padding: 24px;
}

.clinic-map-popup__title {
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: $font-weight-medium;
	text-decoration: underline;
}

.clinic-map-popup__info {
	font-size: 12px;
	font-weight: normal;
	margin-top: 8px;
	margin-bottom: 0;
}
