//design tokens
$clinic-booking-form-bg: $color-brand-primary !default;
$clinic-booking-form-color: $color-white;
$clinic-booking-form-px: 24px;
$clinic-booking-form-header-py: 16px;
$clinic-booking-form-content-my: 32px;
$clinic-booking-form-width: 320px;
$clinic-booking-form-padding-top: 32px;
$clinic-booking-form-content-border-color: $color-brand-primary !default;

.clinic-booking-form {
	display: none;
	background-color: #fff;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out;
}

.clinic-booking-form__header {
	padding: $clinic-booking-form-header-py $clinic-booking-form-px;
	background-color: $clinic-booking-form-bg;
	color: $clinic-booking-form-color;
}

.clinic-booking-form__header-label {
	@extend .label;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: $color-green-medium;
	color: #fff;
}

.clinic-booking-form__header-price {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.price__container {
		align-items: center;
	}

	.icon {
		font-size: 24px;
		color: #fff;
	}
}

.clinic-booking-form__header-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-top: 12px;

	> * + * {
		margin-top: 4px;
	}

	.icon {
		@include spacing(left, 8px);
	}
}

.clinic-booking-form__content {
	padding: $clinic-booking-form-content-my $clinic-booking-form-px;
	border: 1px solid transparent;
}

.clinic-booking-form--lite-picker {
	position: relative;
	.litepicker {
		top: 48px !important;
		left: 0 !important;
	}
}

@include laptop {
	.clinic__booking-close {
		display: none;
	}

	.clinic-booking-form {
		display: block;
		position: sticky;
		top: calc(#{$site-header-height} + #{$clinic-booking-form-padding-top});
		flex: 0 0 $clinic-booking-form-width;
		transform: none;
	}

	.clinic-booking-form__content {
		border: 1px solid $clinic-booking-form-content-border-color;
	}

	.clinic-booking-form--lite-picker {
		.litepicker {
			left: auto !important;
			right: 0;
		}
	}
}

/**
 If the screen is shorter than 880px render the datapicker at the top.
 Because it can't fit below 880px.
 Date input is 475px from the top of the page and the datepicker height is ~400px.
 Minimal height: 475px + 400px = ~875px
 */
@media (max-height: 880px) {
	.clinic-booking-form--lite-picker {
		.litepicker {
			top: auto !important;
			bottom: 100% !important;
		}
	}
}

@include below-laptop {
	.clinic-booking-form {
		overflow: auto;
		@include z-index('reserve-booking');
	}

	.clinic-booking-form--lite-picker {
		display: flex;
		flex-direction: column;
		align-items: center;

		.input-with-icon {
			width: 100%;
		}

		.litepicker {
			top: 0 !important;
			margin-bottom: 0;
		}
	}
}
