//tokens
$clinic-search-container-padding: 0;
$clinic-search-header-padding-y: 12px;
$clinic-search-list-padding: $container-mobile-padding;
$clinic-search-list-width: 100%;
$clinic-search-map-width: 100%;
$clinic-search-card-border: $color-green-light !default;
$clinic-search-view-toggle-height: 64px;
$navigation-height: 64px;
$available-viewport: calc(100vh - #{$site-header-height});
$clinic-search-action-color: $color-green-primary !default;
$clinic-search-action-bg: $color-white;

$clinic-search-filter-border: $color-brand-primary !default;
$clinic-search-filter-background: #fff !default;
$clinic-search-filter-color: $color-brand-primary !default;

$clinic-search-filter-active-border: $color-brand-primary !default;
$clinic-search-filter-active-background: $color-brand-primary !default;
$clinic-search-filter-icon-color: $color-brand-secondary !default;

$clinic-search-map-button-border-radius: inherit !default;
$clinic-search-sort-label-color: $color-green-medium !default;
$clinic-search-sort-icon-color: $color-orange-primary !default;

@include below-tablet {
	body[data-page-name='clinic-search'] {
		padding-bottom: $clinic-search-view-toggle-height;
	}
}

.clinic-search {
	&__container {
		padding: $clinic-search-container-padding;
	}

	&__header-container {
		padding: 0;
		@include flip(padding-left, $clinic-search-list-padding);
		background-color: #fff;
		border-bottom: 1px solid $clinic-search-card-border;
	}

	&__header--desktop {
		display: none;
	}

	&__header--mobile {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 0 0 100vw;
		overflow: hidden;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&__filters-close {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: $navigation-height;
		font-size: 24px;
	}

	&__filters-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow-x: visible;
		@include flip(padding-right, $clinic-search-list-padding);
	}

	&-info-container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.dropdown-button-label {
			cursor: auto;
			color: $clinic-search-sort-label-color;
		}

		.icon {
			color: $clinic-search-sort-icon-color;
			font-size: 24px;
		}
	}

	&__filters-info-list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		> .clinic-search__filters-info + .clinic-search__filters-info {
			margin-top: 8px;
		}
	}

	&__filters-info {
		margin: 0;
	}

	&__filters-label {
		margin: 0;
	}

	&__filters-toggle {
		@extend %reset-button;
		position: relative;
		border-radius: 0;
		padding: 0 0 2px;
		height: auto;
		color: $clinic-search-filter-icon-color;

		.icon {
			font-size: 24px;
		}

		.counter {
			position: absolute;
			top: -(map-get($sizes, sm) / 2);
			@include flip(right, -(map-get($sizes, sm) / 2));
		}
	}

	&__filters-body,
	&__sort-body {
		position: fixed;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		width: 100vw;
		height: 0;
		overflow: auto;
		background-color: #fff;
		transition: height 0.4s ease;
		@include z-index('navigation');

		&.active {
			height: calc(#{$available-viewport} - #{$clinic-search-view-toggle-height});
		}
	}

	&__filters-section {
		display: flex;
		flex-direction: column;
		padding: $clinic-search-list-padding;

		& + & {
			border-top: 1px solid $clinic-search-card-border;
			&.border--none {
				border: none;
			}
		}

		> .clinic-search__filters-item + .clinic-search__filters-item {
			margin-top: 16px;
		}
	}

	&__body {
		position: relative;
	}

	&__map-container {
		position: fixed;
		bottom: 0;
		height: $clinic-search-view-toggle-height;
		overflow: hidden;
	}

	&__map-container--show {
		height: $available-viewport;
		display: flex;
		flex-direction: column-reverse;
	}

	/**
	* Map is wrapped so there could be padding in mobile view.
	* Padding is set in CustomMap.js because of resizing.
	*/
	&__map-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		background: #fff;
	}

	&__map {
		display: flex;
		flex: 1 1 auto;
		height: calc(#{$available-viewport} - #{$clinic-search-view-toggle-height});
	}

	&__view-toggle {
		display: flex;
		flex-shrink: 0;
		width: 100vw;
		height: $clinic-search-view-toggle-height;
		padding-left: $clinic-search-list-padding;
		padding-right: $clinic-search-list-padding;
		background-color: $clinic-search-action-bg;
		border-top: 1px solid $clinic-search-card-border;
	}

	&__map-button {
		@extend .button--reset;
		color: $clinic-search-action-color;
		text-decoration: underline;
	}

	&__list {
		padding: 0 $clinic-search-list-padding $clinic-search-view-toggle-height
			$clinic-search-list-padding;

		> .clinic-card + .clinic-card {
			border-top: 1px solid $clinic-search-card-border;
		}
	}

	&__list-info-container {
		padding: 32px 0;
		border-bottom: 1px solid $clinic-search-card-border;

		> * + * {
			margin: 0;
			margin-top: 32px;
		}

		a {
			text-decoration: underline;
		}
	}

	&-info {
		display: none;
	}
}

.modal {
	.clinic-search__filters-section {
		padding: 0;
	}
}

.clinic-search-filters-modal-container {
	max-width: 812px;
	padding: 0;
	margin-top: 48px;
	margin-bottom: 48px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 48px;

	.heading-3 {
		font-size: 20px;
	}
}

.border--none {
	border: none;
}

.clinic-search__btn--filter {
	@extend .button--reset;
	border: 1px solid $clinic-search-filter-border;
	background-color: $clinic-search-filter-background;
	color: $clinic-search-filter-color;
	padding: 0 16px;
	min-height: 40px;

	& + & {
		@include flip(border-left, none);
	}

	&:hover,
	&-active {
		border-color: $clinic-search-filter-active-border;
		background-color: $clinic-search-filter-active-background;
		color: #fff;
	}
}

.clinic-search-modal-buttons-group {
	display: flex;
	justify-content: flex-end;
	padding: 0;

	.clinic-modal-button {
		padding-right: 44px;
		padding-left: 44px;
	}
}

@include below-laptop {
	.clinic-search-info-container {
		.dropdown {
			display: none;
		}
	}
}

@include laptop {
	$clinic-search-list-padding: $container-desktop-padding;
	$clinic-search-header-padding-y: 16px;

	$clinic-search-list-width: 60%;
	$clinic-search-map-width: 40%;

	.clinic-search {
		&__header-container {
			@include flip(padding-left, $clinic-search-list-padding);
		}

		&__header--mobile {
			display: none;
		}

		&__header--desktop {
			display: flex;
			align-items: center;
			padding: $clinic-search-header-padding-y 0;
			background-color: #fff;
			width: 100%;

			.dropdown-menu {
				min-width: 277px;
			}
		}

		&__header-section {
			display: flex;
			padding: 0 16px;

			& + & {
				@include flip(border-left, 1px solid $clinic-search-card-border);
			}

			> .dropdown + .dropdown {
				@include spacing(left, 16px);
			}
		}

		&__body {
			display: flex;
		}

		&__list {
			width: $clinic-search-list-width;
			padding: 0 $clinic-search-list-padding;
			transition: transform 0.8s cubic-bezier(0.25, 1, 0.5, 1);
		}

		&__map-container {
			position: sticky;
			top: $site-header-height;
			@include flip(right, 0);
			width: $clinic-search-map-width;
			height: $available-viewport;
			flex: 1;
		}

		&__map-container--show {
			width: 100%;
		}

		&__view-toggle {
			position: absolute;
			z-index: 5;
			top: $clinic-search-list-padding;
			@include flip(right, 8px);

			width: initial;
			height: initial;

			padding: 0;
			border-radius: 21px;
		}

		&__map-button {
			min-width: 144px;
			padding: 9px 16px;
			text-decoration: none;
			color: $clinic-search-action-color;
			background-color: $clinic-search-action-bg;
			border-radius: $clinic-search-map-button-border-radius;
		}

		&__map {
			height: 100%;
		}
	}

	.clinic-search-info {
		display: block;
		font-weight: $font-weight-medium;
	}

	.hide-list {
		width: 0;
		padding: 0;
	}
}

.new-booking-platform-search-banner {
	padding: 24px 32px;
	background-color: $color-gray-light;
}
