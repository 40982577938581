$settings-info-margin-bottom: 64px !default;

.settings__container {
	min-height: 100vh;
	margin-top: 32px;
}

.settings__wrapper {
	max-width: 790px;
}

.settings__body {
	max-width: 554px;
}

.settings__info {
	margin-bottom: $settings-info-margin-bottom;

	> * + * {
		margin-top: 24px;
	}
}

.settings__form {
	> * + * {
		margin-top: 32px;
	}
}

.settings-buttons-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

@include below-tablet {
	.settings__form .settings-change {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.settings__form .settings-cancel {
		@include spacing(left, 0px);
		margin-top: 24px;
	}
}

@include laptop {
	.settings__container {
		padding: 0;
		@include flip(padding-left, 150px);
	}
}
