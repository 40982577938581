.home-media-text--gray {
	background-color: $color-gray-light;
}

.home-media-text--orange {
	background-color: $color-orange-light;
}

.home-media-text--gray::before {
	background-color: $color-gray-light;
}

.home-media-text--orange::before {
	background-color: $color-orange-light;
}

.home-media-text-content {
	padding-bottom: 48px;
}

.home-media-text-image-container {
	transform: translate(0, -50%);
	margin-bottom: -25%;
	margin-top: 50%;
	text-align: center;
}

.home-media-text-about__label {
	margin-bottom: 16px;
}

.home-media-text-about__description {
	margin-top: 24px;
	line-height: 24px;
}

.home-media-text-about__button {
	margin-top: 32px;
	z-index: 0;
}

@include laptop {
	.home-media-text-container {
		margin-bottom: 64px;
	}

	.home-media-text {
		margin-top: 80px;
		position: relative;
		z-index: 0;
		background-color: white;
	}

	.home-media-text::before {
		content: '';
		position: absolute;
		width: 75%;
		height: 100%;
		z-index: -1;
	}

	.home-media-text--left-right::before {
		@include flip(right, 0);
	}

	.home-media-text--right-left::before {
		@include flip(left, 0);
	}

	.home-media-text-content {
		display: flex;
		align-items: center;
		padding-top: 64px;
	}

	.home-media-text-content--left-right {
		@include flip(padding-right, 0px);
	}

	.home-media-text-content--right-left {
		flex-direction: row-reverse;
		@include flip(padding-left, 0px);
	}

	.home-media-text-image-container,
	.home-media-text-about {
		flex-basis: 50%;
	}

	.home-media-text-image-container {
		transform: translate(0, 0);
		margin-bottom: 0;
		margin-top: 0;
	}

	.home-media-text-about {
		max-width: 438px;
		margin: 0 auto;
	}

	.home-media-text-about__title {
		margin-top: 8px;
	}

	.home-media-text-about__description,
	.home-media-text-about__button {
		margin-top: 40px;
	}

	.subscribe-lp-wider {
		max-width: 450px;
	}
}

@include below-laptop {
	.home-media-text-content--reverse {
		display: flex;
		flex-direction: column-reverse;

		.home-media-text-about {
			margin-top: 40px;
		}
	}
}
