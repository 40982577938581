$lp-overlay-padding: 40px;
$lp-container-width: 1096px;
$lp-container-gutter: 24px !default;
$lp-link-font-size: 24px !default;
$lp-link-gutter: 44px !default;
$lp-padding-l: 100px !default;
$lp-padding-m: 64px !default;
$lp-max-width: 1096px + 2 * ($lp-container-gutter) !default;
$lp-list-margin-top: 48px !default;

.language-picker {
	.language-picker__body {
		padding: 0;
		max-width: $lp-max-width;
	}

	.language-picker__container {
		margin: 0 auto;
		padding: 0 $lp-container-gutter;
	}

	.language-picker__item--active .language-picker__link {
		text-decoration: underline;
	}

	.language-picker__link {
		display: inline-flex;
		align-items: center;
		font-size: $lp-link-font-size;
	}

	.language-picker__list {
		margin-top: 32px;
	}

	.language-picker__info {
		margin-inline-start: 6px;
		font-size: 16px;
	}

	.language-picker__item {
		font-size: 20px;
		margin-bottom: 24px;
	}
}

@include below-tablet {
	.language-picker__container .modal__header {
		flex-wrap: wrap;
		justify-content: flex-end;
	}

	.language-picker__container .modal__close {
		margin: 20px 0;
	}

	.language-picker__container .language-picker__title {
		flex: 1 0 100%;
		order: 2;
	}
}

@include tablet {
	.language-picker {
		.language-picker__body {
			flex: 1 0 auto;
			//max-width: 100%;
			padding: $lp-padding-m 0;
		}

		.language-picker__title {
			margin: 0;
		}

		.language-picker__list {
			display: flex;
			flex-flow: wrap;
			margin: $lp-list-margin-top (-$lp-link-gutter/2) 0;
		}

		.language-picker__item {
			flex: 0 0 33%;
			padding: 0 $lp-link-gutter/2;
			margin-bottom: 32px;
		}

		.language-picker__overlay {
			padding: 40px;
		}
	}
}

@include laptop {
	.language-picker {
		.language-picker__body {
			padding: $lp-padding-l 0;
		}
	}

	.language-picker__item {
		flex-basis: 25%;
	}
}
