.input-stepper {
	display: inline-flex;
	border: 1px solid $stepper-border-color;
	font-size: 24px;
}

.input-stepper__button {
	@extend %reset-button;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: $stepper-height;
	transition: background-color 0.2s ease-in-out;
	background-color: $stepper-button-background;
	color: $stepper-button-color;

	&:first-child {
		@include flip(border-right, 1px solid $stepper-border-color);
	}
	&:nth-child(n + 2) {
		@include flip(border-left, 1px solid $stepper-border-color);
	}

	&:disabled {
		background-color: $stepper-button-disabled-background;
		color: $stepper-button-disabled-color;
	}
}

.input-stepper__button,
.input-stepper__input {
	height: $stepper-height;
	text-align: center;
	outline: 0;
}

.input-stepper__input {
	width: 90px;
	color: $stepper-input-color;
	appearance: textfield;

	&:disabled {
		opacity: 1;
		color: $stepper-input-disabled-color;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

// Error State

.input-stepper.error {
	border-color: $stepper-button-error-border-color;
}
