$clinic-about-overview-title-font-size: 12px !default;
$clinic-about-title-margin-bottom: 20px !default;
$clinic-overview-item-icon-color: $color-orange-primary !default;

.clinic__about-title {
	margin-bottom: $clinic-about-title-margin-bottom;
}

.clinic__about-overview {
	display: flex;
	flex-direction: column;
}

.clinic__about-overview-title {
	margin-bottom: 8px;
}

.clinic__overview {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr;
}

.clinic__overview-item {
	display: flex;
	align-items: center;

	.item-help {
		padding-inline-start: 8px;

		svg {
			color: $color-green-primary;
			font-size: 20px;
		}
	}

	.icon {
		margin-inline-end: 8px;
		font-size: 24px;
		color: $clinic-overview-item-icon-color;
	}
}

@include small {
	.clinic__overview {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 24px;
	}
}
@include laptop {
	.clinic__about-overview {
		flex-direction: row;
		align-items: center;
	}

	.clinic__about-overview-title {
		margin-bottom: 0;
		margin-inline-end: 36px;
		font-size: $clinic-about-overview-title-font-size;
	}

	.clinic__overview {
		grid-template-columns: repeat(3, 1fr);
	}

	.clinic__overview--small {
		grid-template-columns: repeat(3, auto);
		grid-gap: 32px;
	}
}
