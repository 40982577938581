.booking-status {
	background-color: $booking-status-background-color;
	padding: 24px;
}

.booking-status__message-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $booking-status-message-background-color;
	border: 1px solid $booking-status-message-border-color;
	border-radius: 2px;

	svg {
		color: $booking-status-message-border-color;
		font-size: 24px;
	}

	&.booking-status__message-container--declined {
		background-color: $booking-status-message-background-color-red;
		border: 1px solid $booking-status-message-border-color-red;

		svg {
			color: $booking-status-message-border-color-red;
			font-size: 24px;
		}
	}
}

.booking-status__content {
	margin: 0;
}

.booking-status__content dt {
	margin-top: 24px;
	font-size: 12px;
	text-transform: uppercase;
	color: $booking-status-content-color;
}

.booking-status__content dd + .booking-status__content dt {
	margin-top: 32px;
}

.booking-status__content dd {
	margin-inline-start: 0;
	margin-top: 8px;
}
