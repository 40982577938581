$clinic-score-number-bg-color: $color-orange-primary !default;
$clinic-score-description-grade-color: $color-brand-secondary !default;
$clinic-score-description-review-count-color: inherit !default;
$clinic-score-description-grade-font-size: 12px !default;
$category-score-number-small-width: 32px !default;
$category-score-number-small-height: 32px !default;
$category-score-number-color: $color-orange-primary !default;
$category-score-number-font-weight: 500 !default;
$category-score-number-spacing: 4.5px !default;
$single-review-author-score-color: $color-orange-primary !default;
$single-review-author-desktop-font-size: 24px !default;
$clinic-review-response-font-weight: 500 !default;
$single-review-date-color: $color-green-medium !default;

.clinic-score {
	&__container {
		flex-shrink: 0;
	}

	&__number {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 64px;
		height: 64px;
		background-color: $clinic-score-number-bg-color;
		color: $color-white;

		&--small {
			width: $category-score-number-small-width;
			height: $category-score-number-small-height;
		}
	}

	&__grade {
		color: $clinic-score-description-grade-color;
		font-weight: 700;
	}

	&__review-count {
		color: $clinic-score-description-review-count-color;
	}

	&__description {
		padding: 6px 0;

		p {
			margin: 0;
		}

		p + p {
			margin-top: 4px;
		}

		&--small {
			font-size: $clinic-score-description-grade-font-size;

			.clinic-score__grade {
				font-weight: $font-weight-medium;
			}
		}
	}
}

.category-score {
	&__container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 16px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&__title {
		font-weight: $font-weight-medium;
	}

	&__number {
		color: $category-score-number-color;
		font-weight: $category-score-number-font-weight;
		letter-spacing: $category-score-number-spacing;
	}
}

.reviews-list {
	margin-top: 64px;
}

.single-review-container {
	padding-bottom: 24px;
	border-bottom: 1px solid $global-border-color;
}

.single-review-container + .single-review-container {
	margin-top: 24px;
}

.single-review-date {
	color: $single-review-date-color;
}

.single-review-author p,
.single-review-date,
.single-review-clinic-response-title,
.single-review-clinic-response {
	margin-top: 0;
	margin-bottom: 0;
}

.single-review-author,
.single-review-clinic-response-title {
	font-weight: $clinic-review-response-font-weight;
}

.single-review-author-score {
	color: $single-review-author-score-color;
}

.single-review {
	margin-top: 16px;
	margin-bottom: 0;
}

.single-review,
.single-review-clinic-response {
	font-size: 14px;
}

.single-review-clinic-response-container {
	@include spacing(left, 40px);
}

@include small {
	.category-score__container {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 36px;
		column-gap: 32px;
	}
}

@include laptop {
	.single-review-container {
		padding-bottom: 32px;
	}

	.single-review-container + .single-review-container {
		margin-top: 32px;
	}

	.single-review-author {
		font-size: $single-review-author-desktop-font-size;
	}

	.single-review,
	.single-review-clinic-response {
		font-size: 16px;
	}

	.single-review-clinic-response-container {
		margin-top: 32px;
		@include spacing(left, 85px);
	}
}
