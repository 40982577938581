$autocomplete-item-color: $color-brand-primary !default;
$autocomplete-item-header-color: $color-brand-secondary !default;
$autocomplete-item-hover-bg: $color-orange-light !default;
$autocomplete-item-disabled-color: $color-green-medium !default;

$autocomplete-list-boxshadow: 3px 3px 16px rgba(0, 0, 0, 0.16) !default;
$autocomplete-item-secondary-info-color: $color-brand-secondary !default;

$autocomplete-button-search-color: $color-orange-primary !default;
$autocomplete-button-search-bg-color: $color-orange-light !default;
$autocomplete-button-search-border-top: 1px solid $color-orange-primary !default;

.autocomplete {
	display: flex;
	flex-direction: column;
}

.autocomplete__results {
	position: relative;
	display: none;

	&.active {
		display: initial;
	}
}

.autocomplete__list {
	position: absolute;
	width: 100%;
	@include z-index('dropdown');
}

.autocomplete__item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	min-height: 76px;

	padding: 16px 24px;
	background-color: #fff;
	color: $autocomplete-item-color;

	cursor: pointer;

	&:hover {
		background-color: $autocomplete-item-hover-bg;
	}

	&[disabled] {
		cursor: not-allowed;
		color: $autocomplete-item-disabled-color;
	}

	.icon {
		@include spacing(right, 12px);
		font-size: 20px;
	}

	&-header {
		@extend .autocomplete__item;

		min-height: 56px;
		color: $autocomplete-item-header-color;
	}
}

.autocomplete__item-main-info {
	display: flex;

	.icon {
		flex-shrink: 0;
	}
}

.autocomplete__item-secondary-info {
	color: $autocomplete-item-secondary-info-color;
	text-transform: uppercase;
}

.site-header .autocomplete__list {
	box-shadow: $autocomplete-list-boxshadow;
}

// autocomplete on homepage module
.autocomplete__button--search {
	justify-content: center;
	padding: 16px;
	color: $autocomplete-button-search-color;
	background-color: $autocomplete-button-search-bg-color;
	border-top: $autocomplete-button-search-border-top;
	border-radius: 0;
}
