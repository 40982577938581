$home-hero-image-height: 375px;
$home-hero-padding-y: 32px;
$home-hero-search-icon-color: $color-orange-primary !default;
$autocomplete-icon-font-size: 40px !default;
$autocomplete-form-font-size: 20px !default;
$autocomplete-input-group-padding-top: 24px !default;
$autocomplete-input-group-padding-bottom: 24px !default;
$autocomplete-input-group-border-top: 1px solid $color-orange-primary !default;
$autocomplete-form-flex-direction-laptop: row !default;

.home-hero {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	padding: 32px $container-mobile-padding
		calc((#{$home-hero-image-height} / 2) + #{$home-hero-padding-y});
	background-color: $color-brand-secondary;
	color: #fff;

	&__image-wrapper {
		position: absolute;
		bottom: 0;
		transform: translateY(50%);
		padding: 0 $container-mobile-padding;
		line-height: 0;
	}

	&__image {
		width: 100%;
		height: $home-hero-image-height;
		object-fit: cover;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__title {
		margin-bottom: 8px;
	}

	&__subtitle {
		margin-bottom: 32px;
	}
}

@include laptop {
	.home-hero {
		flex-direction: row;
		padding: 0 0;
	}

	.home-hero__image-wrapper {
		position: initial;
		bottom: initial;
		transform: translateY(0);
		height: 100%;
		width: 30%;
		padding: 0;
	}

	.home-hero__image {
		min-width: 100%;
		height: 100%;
		flex-shrink: 0;
	}

	.home-hero__body {
		align-items: stretch;
		width: 70%;
		height: 100%;
		padding: 0 64px;
	}

	.home-hero__title {
		margin-bottom: 16px;
	}

	.home-hero__subtitle {
		margin-bottom: 64px;
	}
}

.ml-auto {
	margin-left: auto;
}
