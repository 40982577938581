$info-box-padding-x-small: 16px !default;
$info-box-padding-y-small: 16px !default;
$info-box-font-size-small: 12px !default;

$info-box-padding-x-medium: 18px !default;
$info-box-padding-y-medium: 16px !default;
$info-box-padding-x-large: 26px !default;
$info-box-padding-y-large: 24px !default;
$info-box-bg-color: $color-orange-light !default;
$info-box-color: $color-brand-primary !default;

$info-box-spacing-x: 8px !default;
$info-box-spacing-y: 8px !default;
$info-box-font-weight: 300 !default;

.info-box {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	background-color: $info-box-bg-color;
	color: $info-box-color;
	font-weight: $info-box-font-weight;

	.icon {
		flex-shrink: 0;
		font-size: 24px;
	}

	> * + * {
		margin-inline-start: $info-box-spacing-x;
	}

	&__cta {
		color: inherit;
		cursor: pointer;
	}

	&--small {
		padding: $info-box-padding-y-small $info-box-padding-x-small;

		&,
		.button {
			font-size: $info-box-font-size-small;
		}

		.button {
			height: 16px;
		}
	}

	&--medium {
		padding: $info-box-padding-y-medium $info-box-padding-x-medium;
	}

	&--large {
		padding: $info-box-padding-y-large $info-box-padding-x-large;
	}

	&--center {
		justify-content: center;
	}
}
