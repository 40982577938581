.new-booking-platform-alert {
	align-items: flex-start;
	gap: 8px;
	background-color: $color-orange-light;
	padding: 16px;

	&__icon {
		width: 24px;
		height: 32px;
	}

	&__content {
		gap: 8px;

		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}
}
