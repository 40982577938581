.rich-text-editor {
	a {
		text-decoration: underline;
		text-underline-offset: 4px;
		cursor: pointer;
	}
	ul,
	ol {
		padding-inline-start: 18px;
	}
}
