:root {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	position: relative;
	width: 100%;
	margin: 0;
	color: $global-font-color;
	font-family: $global-font-family;
	font-size: $global-font-size;
	direction: $global-text-direction;
	background-color: $color-white;
}

#app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

img,
picture,
video {
	max-width: 100%;
	display: block;
}

a {
	color: $global-link-font-color;

	&:hover {
		color: $global-link-hover-font-color;
	}
}

.underline {
	text-decoration: underline;
}

hr {
	border: 0;
	height: 1px;
	margin-top: 32px;
	margin-bottom: 32px;
	background-color: $global-border-color;
}

small {
	font-size: $global-small-font-size;
}

strong {
	font-weight: $font-weight-medium;
}

// Offset the scroll to anchor by header height.
:target {
	scroll-margin-top: $site-header-height;
}

input[type='time']::-webkit-calendar-picker-indicator {
	position: relative;
	left: 52px;
	opacity: 0;
	transform: scale(2.1);
}

// Debug classed used for sanity check on centering
[debug] {
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
	}

	&::before {
		top: 50%;
		right: 0;
		left: 0;
		height: 1px;
		background: red;
	}

	&::after {
		top: 0;
		bottom: 0;
		left: 50%;
		width: 1px;
		background: green;
	}
}

// mirring images for rtl mode
@include rtl {
	.img--mirror {
		transform: scaleX(-1);
	}

	.img--mirror-bg {
		&::after,
		&::before {
			transform: scaleX(-1);
		}
	}
}
