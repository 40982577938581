/**
	* @see {@link https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/}
 */
.hide-sr {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
