$faq-container-margin: 32px 0 !default;
$faq-container-laptop-margin: 80px auto !default;
$faq-container-max-width: 1206px !default;
$faq-title-width: 40% !default;
$faq-title-margin: 32px !default;
$faq-accordion-width: 60% !default;

.faq-container {
	margin: $faq-container-margin;
}

.faq-title {
	flex-basis: $faq-title-width;
}

.faq-container .accordion {
	flex-basis: $faq-accordion-width;
	flex-grow: 1;
}

@include laptop {
	.faq-container {
		display: flex;
		max-width: $faq-container-max-width;
		margin: $faq-container-laptop-margin;
	}

	.faq-title {
		@include spacing(right, $faq-title-margin);
	}
}
