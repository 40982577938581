*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
	margin: 0;
	scroll-behavior: smooth;
}

// These should be configured per project basis
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
}

%reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* Remove padding and list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
	@extend %reset-list;
}

a {
	text-decoration: none;
}

/* Anchor elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
	font: inherit;
}

button {
	cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 1ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 1ms !important;
		scroll-behavior: auto !important;
	}
}
