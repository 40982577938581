$hc-section-background: $color-gray-light;
$hc-header-desktop-width: 80%;
$hc-header-background: $color-brand-primary;
$hc-header-color: #fff;

.home-countries {
	background-color: $hc-section-background;
	width: 100%;
}

.home-countries__header {
	padding-top: $home-hero-image-height / 2;
	background-color: $hc-header-background;
	color: $hc-header-color;
}

.home-countries__nav,
.home-countries__title,
.home-countries__subheader,
.home-countries__slider,
.home-countries__controls {
	// Imitate a container
	padding: 0 $container-mobile-padding;
}

.home-countries__title {
	margin: 24px 0;
}

.home-countries__nav {
	display: flex;
	align-items: center;
	height: 48px;
	overflow-y: hidden;
	overflow-x: auto;
}

.home-countries__link {
	@extend %reset-button;
	color: #fff;
	white-space: nowrap;

	&.active {
		border-bottom: 2px solid #fff;
	}
	& + & {
		margin-inline-start: 24px;
	}
}

.home-countries__subheader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 32px 0 24px 0;
}

.home-countries__controls {
	margin-top: 16px;
	padding-bottom: 24px;
}

.home-countries__control {
	@extend %reset-button;
	font-size: 24px;
	color: $color-brand-primary;
	transition: opacity 0.2s ease-in-out;

	& + & {
		margin-inline-start: 12px;
	}

	&.glide__arrow--disabled {
		opacity: 0.5;
	}
}

.home-countries__country {
	display: block;
}

.home-countries__country-image {
	margin-bottom: 24px;
	flex: 0 0 auto;
	width: 303px;
	height: 280px;
	object-fit: cover;
}

@include laptop {
	.home-countries__header {
		padding-top: 40px;
		margin-inline-start: 100% - $hc-header-desktop-width;
		width: $hc-header-desktop-width;
	}

	.home-countries__subheader,
	.home-countries__slider,
	.home-countries__controls {
		padding-inline-start: 148px;
	}

	.home-countries__country-image {
		width: 437px;
		height: 300px;
	}
}
