.empty__state {
	&-banner {
		margin-bottom: 15px;
		padding: 30px;
		background-color: #f8f8f8;
		color: #1c5335;

		.st0 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #db256e;
		}
		.st1 {
			fill-rule: evenodd;
			clip-rule: evenodd;
			fill: #5495c4;
		}
	}

	&--left {
		margin-bottom: 15px;
	}

	&-heading {
		margin: 0 0 10px 0;
		font-size: 20px;
	}

	&-button {
		border-radius: 500px;
		display: inline-flex;
		align-items: center;
		padding: 0 24px;
		height: 60px;
		background-color: #e86825;
		color: #fff;
		text-decoration: none;
		transition: background-color 0.2s ease-in-out;
		&:hover {
			background-color: #1c5335;
			color: #fff;
		}
	}

	&-icon {
		margin-inline-start: 15px;
		width: 20px;
		height: 20px;
	}
}

@include laptop {
	.empty__state-banner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.empty__state--left {
		margin-bottom: 0;
	}
}
