$accordion-item-header-text-decoration: underline !default;
$accordion-item-border-color: $color-green-primary !default;
$accordion-item-border-top-color: $color-green-primary !default;
$accordion-item-header-icon-color: $color-orange-primary !default;
$accordion-item-header-padding: 16px !default;
$accordion-item-header-laptop-padding: 24px !default;
$accordion-item-padding: 12px !default;
$accordion-item-laptop-padding: 32px !default;
$accordin-item-panel-max-width: 70% !default;

.accordion__item {
	@include flip(padding-right, $accordion-item-padding);
	border-bottom: 1px solid $accordion-item-border-color;

	&:first-child {
		border-top: 1px solid $accordion-item-border-top-color;
	}
}

.accordion__item-header {
	@extend %reset-button;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: $accordion-item-header-padding;
	padding-bottom: $accordion-item-header-padding;

	.accordion__item-header-title {
		line-height: 1.2;
		text-align: start;
	}

	&:hover .accordion__item-header-title {
		text-decoration: $accordion-item-header-text-decoration;
	}
}

.accordion__item-panel {
	height: 0;
	max-width: $accordin-item-panel-max-width;
	overflow: hidden;
	visibility: hidden;
	@include flip(margin-left, auto);
	transition: height 0.2s ease-in-out;
	will-change: height;
}

.accordion__item-description {
	padding-bottom: 16px;

	*:first-child {
		margin-top: 0;
	}
	*:last-child {
		margin-bottom: 0;
	}
}

.accordion__item.active .accordion__item-panel {
	visibility: visible;
}

.accordion__item-header-icon {
	color: $accordion-item-header-icon-color;
	font-size: 20px;
	transition: transform 0.2s ease-in-out;
}

.accordion__item.active .accordion__item-header-icon {
	transform: rotate(180deg);
}

@include laptop {
	.accordion__item {
		@include flip(padding-right, $accordion-item-laptop-padding);
	}

	.accordion__item-header {
		padding-top: $accordion-item-header-laptop-padding;
		padding-bottom: $accordion-item-header-laptop-padding;
	}

	.accordion__item-header-icon {
		font-size: 24px;
	}

	.accordion__item-description {
		padding-bottom: 24px;
	}
}
