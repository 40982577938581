$checkbox-base-font-size: 16px;
$checkbox-base-square-size: 32px;
$checkbox-base-square-margin: 10px;
$checkbox-base-check-width: 7px;
$checkbox-base-check-height: 17px;
$checkbox-base-check-color: $color-brand-secondary;
$checkbox-error-border-color: $color-red-main;

.checkbox {
	position: relative;
	display: inline-flex;
	align-items: center;
	font-weight: 400;

	input {
		display: grid;
		place-content: center;
		appearance: none;
		background-color: $checkbox-background-color;
		width: $checkbox-base-square-size;
		height: $checkbox-base-square-size;
		margin: 0;
		margin-inline-end: $checkbox-base-square-margin;
		border: 1px solid $checkbox-border;
		flex-shrink: 0;
	}

	input:checked {
		background-color: $checkbox-background-color-checked;
		border-color: $checkbox-border-checked;
	}

	input::after {
		content: '';
		width: $checkbox-base-check-width;
		height: $checkbox-base-check-height;
		border: solid $checkbox-checkmark;
		border-width: 0 2px 2px 0;
		margin-top: -3px;
		transform: scale(0);
		transition: transform 0.2s ease;
	}

	input:checked::after {
		transform: scale(1);
		transform: rotate(45deg);
	}

	&.checkbox--error input {
		border: 2px solid $checkbox-error-border-color;
	}
}
