.newsletter-links-item {
	font-weight: 500;

	& + & {
		margin-top: 64px;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.heading-1 {
		font-weight: 500;
		margin-bottom: 48px;
	}

	&__link {
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 6px;
		font-size: 20px;
		line-height: 1.5;
		transition: color 0.2s ease-in-out;

		& + .newsletter-links-item__link {
			margin-top: 24px;
		}
	}
}
