.upcoming-booking__title {
	display: flex;
	justify-content: space-between;
}

.upcoming-booking__icon {
	font-size: 24px;
	color: $patient-upcoming-booking-icon-color;
}

.new-patient-header {
	flex-direction: column;
	align-items: flex-start;
	border: 0;
	margin-top: 24px;
}

@include media-breakpoint-down(small) {
	.new-patient-header .booking__links {
		flex-direction: column;
		align-items: flex-start;
	}

	.new-patient-header .booking__links > * + * {
		@include spacing(left, 0);
		margin-top: 16px;
	}
}

@include laptop {
	.new-patient-header {
		flex-direction: row;
		align-items: center;
		margin-top: 32px;
	}

	.upcoming-booking__title .booking__links {
		margin-top: 0;
	}

	.single-patient__sidebar--laptop {
		padding-top: 32px;
	}
}
