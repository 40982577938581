.additional-details-label {
	display: inline-block;
	margin-top: 32px;
}

.add-patient-health-insurance {
	padding-bottom: 0;
}

.upcoming-treatment {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 24px;
}

.upcoming-treatment + .upcoming-treatment {
	border-top: 1px solid $color-green-light;
	padding-top: 24px;
}

.upcoming-treatment__date,
.upcoming-treatment__num {
	font-weight: $font-weight-medium;
}

.see-all-treatments {
	padding-bottom: 0;
}

.weekly-template__buttons {
	padding-top: 32px;
	border-top: 1px solid $color-green-light;
}
