$grid-border-color: $color-green-light !default;
$grid-columns: 7;
$grid-gutter: 16px;
$grid-sibling-spacing: 32px;

.booking-grid {
	margin-bottom: $grid-sibling-spacing;
	padding-bottom: $grid-sibling-spacing;
	border-bottom: 1px solid $grid-border-color;
}

.booking-grid__wrapper,
.booking-grid__row {
	display: flex;
	align-items: flex-start;
	width: 100%;
}

.booking-grid__row {
	margin: 0 (-1 * ($grid-gutter / 2));
}

.booking-grid__daytime {
	position: sticky;
	top: calc(#{$site-header-height} + #{$booking-header-padding-vertical});
	flex: 0 0 84px;
	margin-inline-end: 32px;
	font-size: 12px;
	@include z-index('sticky-header');
}

.booking-grid__column {
	flex: 0 0 (100 / $grid-columns) * 1%;
	max-width: (100 / $grid-columns) * 1%;
	padding: 0 $grid-gutter / 2;
}

@include media-breakpoint-down(desktop) {
	// Because the card will try to fit, but we allow overflow (horizontal scroll)
	.booking-grid__column {
		min-width: 170px;
	}
}

@include media-breakpoint-up(desktop) {
	// Limit the row width so the cards can resize
	.booking-grid__row {
		overflow: hidden;
	}
}

@media print {
	.booking-grid__daytime {
		margin-inline-end: 24px;
	}
}
