$upload-document-padding: 20px 0 !default;
$upload-document-border-color: $color-green-light !default;
$upload-document-border: 1px solid $upload-document-border-color !default;

$upload-document-download-border-color: $color-brand-primary;
$upload-document-download-border: 1px solid $upload-document-download-border-color !default;
$upload-document-download-border-radius: 50% !default;
$upload-document-download-margin-right: 30px !default;
$upload-document-download-padding: 14px !default;
$upload-document-download-bg: #fff !default;
$upload-document-download-icon-size: 24px !default;

$upload-document-remove-margin: auto !default;

$upload-document-download-animation-from: $color-brand-primary !default;

.w-upload__documents {
	display: flex;
	align-items: center;
	padding: $upload-document-padding;
	border-bottom: 1px solid $upload-document-border;
}

.upload__document--download {
	border: $upload-document-download-border;
	border-radius: $upload-document-download-border-radius;
	margin-inline-end: $upload-document-download-margin-right;
	padding: $upload-document-download-padding;
	background-color: $upload-document-download-bg;

	svg {
		font-size: $upload-document-download-icon-size;
	}

	&--loading {
		animation: reappearing 1s;
		animation-iteration-count: infinite;
	}
}

.upload__document-info {
	margin: 0;
	line-height: 1.5;

	&--name {
		font-weight: $font-weight-medium;
		line-break: anywhere;
	}

	&.error {
		color: $color-red-main;
	}
}

.upload__document-info + .upload__document-info {
	margin-top: 4px;
}

.upload__document--remove {
	margin-inline-start: $upload-document-remove-margin;
	cursor: pointer;
	border: none;
	background: none;
	color: inherit;
	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 0.8;
	}
}

@keyframes reappearing {
	from {
		opacity: 0;
		background-color: $upload-document-download-animation-from;
		color: #fff;
	}

	to {
		opacity: 1;
		background-color: inherit;
		color: $upload-document-download-animation-from;
	}
}
