.newsletter {
	background-color: $color-orange-light;
	padding-top: 80px;
	padding-bottom: 80px;

	&.gray-background {
		background-color: #f8f8f8;
	}
}

.newsletter-content {
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
}

.newsletter-inputs {
	.newsletter-email-submit-container {
		display: flex;
		flex-direction: row;
	}

	.input-with-icon {
		&:before {
			display: none;
		}

		.input {
			padding-inline-end: 16px;
		}
	}

	.newsletter-email-submit-container__button {
		padding: 16px 24px;
	}
}

.newsletter-checkbox {
	align-self: flex-start;
}

@include below-tablet {
	.newsletter-inputs {
		.newsletter-email-submit-container {
			flex-direction: column;

			.newsletter-email-submit-container__button {
				margin-top: 16px;
			}
		}
	}
}

@include laptop {
	.newsletter-content {
		grid-template-columns: 1fr 1fr;
		max-width: 1142px;
		margin: 0 auto;
	}
}
