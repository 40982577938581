$progress-bar-bg-color: $color-green-light !default;
$progress-bar-percent-bg-color: $color-green-primary !default;

.progress-bar {
	margin-top: 11px;
	height: 8px;
	background-color: $progress-bar-bg-color;
}

.progress-bar-percent {
	width: 85%;
	height: 100%;
	background-color: $progress-bar-percent-bg-color;
}
