$breadcrumb-active-font-weight: 500 !default;
$breadcrumb-padding-y: 16px !default;
$breadcrumb-padding-x: 32px;

.breadcrumb {
	padding: $breadcrumb-padding-y 0;

	&--indent {
		padding-left: $breadcrumb-padding-x;
		padding-left: $breadcrumb-padding-x;
	}
}

.breadcrumb-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
}

.breadcrumb li::after {
	padding: 0 8px;
	content: '/';
}

.breadcrumb li:last-child {
	font-weight: $breadcrumb-active-font-weight;

	&::after {
		padding: 0;
		content: '';
	}
}
