$documentation-status-bg: $color-gray-light !default;
$documentation-status-title-color: $color-green-medium !default;
$documentation-status-title-font: 12px !default;
$documentation-status-title-text-transform: uppercase !default;

.documentation-status-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 24px;
	background-color: $documentation-status-bg;
}

.documentation-status__title {
	color: $documentation-status-title-color;
	font-size: $documentation-status-title-font;
	text-transform: $documentation-status-title-text-transform;
}

.documentation-status__description {
	margin-top: 8px;
	margin-bottom: 0;
}
