$clinic-spacing-x: 16px;
$clinic-main-spacing-x: 32px;
$clinic-main-max-width: 908px;
$clinic-mobile-reserve-background: $color-orange-primary !default;
$clinic-profile-section-margin-bottom: 80px !default;

.clinic__main--section {
	margin-bottom: 24px;
}

.clinic__reserve-button {
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 100%;
	height: 64px;
	background-color: $clinic-mobile-reserve-background;
	color: $color-white;
	@include z-index('reserve-booking-button');

	@include rtl {
		right: 0;
	}

	.icon {
		font-size: 22px;
		fill: $color-white;
		margin-inline-start: 24px;
	}
}

@include laptop {
	.clinic {
		width: 100%;
		padding: $clinic-spacing-x 0;
	}

	.clinic__container {
		display: flex;
		align-items: flex-start;
	}

	.clinic__main {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-inline-end: $clinic-main-spacing-x;
		flex-grow: 1;
	}

	.clinic__main--section {
		width: 100%;
		max-width: 672px;
		margin: 0 auto $clinic-profile-section-margin-bottom;
	}

	.clinic__main--about-us {
		display: flex;

		.clinic-share-button-container {
			flex-shrink: 0;
		}
	}

	.clinic-share-button {
		position: relative;
	}

	.clinic__reserve-button {
		display: none;
	}
}
