$booking-header-padding-vertical: 24px;
$booking-header-border-color: $color-green-light !default;
$booking-today-color: $color-brand-secondary !default;
$booking-nav-icon-color: $color-brand-secondary !default;
$booking-nav-label-color: $color-green-medium !default;

.booking__header {
	padding-bottom: 32px;
	border-bottom: 1px solid $booking-header-border-color;
}

.booking__header-date {
	display: flex;
	align-items: center;
	align-self: stretch; // So the left border fits
	font-size: 20px;
}

.booking__nav {
	font-size: 24px;

	.icon-wrapper {
		color: $booking-nav-icon-color;
	}
}

.booking__nav-button {
	margin: 0 16px;
}

.booking__heading {
	padding: $booking-header-padding-vertical 0;
}

.booking__label {
	font-size: 12px;
	color: $booking-nav-label-color;
}

.booking__heading-day-number {
	display: inline-flex;
	align-items: center;
	margin-right: 4px;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.booking__heading-day--active {
	.booking__heading-day-text {
		color: $booking-today-color;
	}

	.booking__heading-day-number {
		background-color: $booking-today-color;
		color: #fff;
	}
}

@include media-breakpoint-down(desktop) {
	.booking {
		overflow-x: auto;
	}
}

@include media-breakpoint-down(laptop) {
	.booking__header-wrapper,
	.booking__links {
		> * + * {
			margin-top: 16px;
		}
	}

	.booking__header-wrapper {
		margin-bottom: 24px;
	}
}

@include media-breakpoint-up(laptop) {
	.booking__header,
	.booking__header-wrapper,
	.booking__links {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.booking__links {
		> * + * {
			margin-inline-start: 32px;
		}
	}

	.booking__header-date {
		margin: 0 16px;
		@include padding(left, 16px);
		@include flip(border-left, 1px solid $color-green-light);
	}
}

@include media-breakpoint-up(desktop) {
	.booking__heading-wrapper {
		position: sticky;
		top: $site-header-height;
		background-color: #fff;
		@include z-index('sticky-header');
	}
}

@media print {
	.booking__header-date {
		margin: 0;
		padding: 0;
		border: 0;
	}
}
