.our-stories-navigation-wrapper {
	position: sticky;
	top: $site-header-height;
	@include z-index('sticky-header');
}

.is-sticky {
	.our-stories-navigation {
		width: 100%;
		transition: width 0.3s ease-in-out;
	}
}

.our-stories-navigation {
	background-color: $color-brand-primary;
	justify-content: space-between;
	width: calc(100% - 32px);
	padding: 0 24px;
}

.our-stories-navigation.mobile {
	width: 100%;
	display: flex;
}

.our-stories-navigation.laptop {
	display: none;
}

.our-stories-categories {
	padding: 12px 0;
	display: flex;
	flex-shrink: 0;
}

.our-stories-category {
	color: $color-white;
	margin-inline-end: 24px;
	transition: color 0.2s ease-in-out;
}

.our-stories-category.active {
	border-bottom: 2px solid $color-orange-light;
	color: $color-orange-light;
}

.our-stories-category:hover {
	color: $color-orange-light;
}

.mobile {
	.our-stories-category {
		color: $color-white;
		background-color: transparent;
		outline: none;
		border: 0;
		padding: 2px 0;
		border-bottom: 2px solid $color-white;
	}
}

.our-stories-search {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
}

.our-stories-search-input {
	max-width: 500px;
	outline: none;
	background-color: $color-orange-light;
	visibility: hidden;
	width: 0;
	transition: width 0.3s ease-in-out;
}

.our-stories-search-button {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 50px;
	background: none;
	outline: inherit;
	border: none;
	color: $color-white;
	font-size: 22px;
}

.our-stories-search-button.submit {
	background-color: $color-orange-light;
	color: $color-brand-secondary;
	visibility: hidden;
	width: initial;
	padding: initial;

	.icon {
		width: 0;
		height: 0;
	}
}

.our-stories-search.active .our-stories-search-input {
	color: $color-brand-primary;
	visibility: visible;
	width: 100%;
	padding: 0 16px;
}

.our-stories-search.active .our-stories-search-button.submit {
	visibility: visible;
	padding: 1px 6px;
	width: 50px;

	.icon {
		width: 22px;
		height: 22px;
	}
}

.our-stories-search.active .our-stories-search-button.activate {
	visibility: hidden;
	width: initial;
	padding: initial;

	.icon {
		width: 0;
		height: 0;
	}
}

.our-stories-container-header {
	padding-left: 0;
}

.clinic-search__filters-body.active {
	height: calc(calc(100vh - var(--site-header-height, 0)) + 1px);
}

.our-stories-search-results-container {
	width: 100%;
	margin: 0 auto;
	padding: 32px 24px 40px 24px;
}

.our-stories-search-results-form {
	display: flex;
	align-items: center;
	padding-bottom: 16px;
	margin: 24px 0 16px;
	border-bottom: 1px solid $color-brand-secondary;
}

.our-stories-search-results-icon {
	color: $color-brand-secondary;
}

.our-stories-search-results-input {
	color: $color-brand-primary;
	outline: none;
	flex-grow: 1;
	margin: 0 8px;
}

.our-stories-search-results-button {
	color: $color-brand-primary;
	background: none;
	border: none;
}

.our-stories-search-results-icon,
.our-stories-search-results-button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	width: 24px;
	height: 24px;
}

.our-stories-search-results-label {
	font-size: 12px;
	margin: 0;
	padding-bottom: 32px;
	border-bottom: 1px solid $color-green-light;
}

.our-stories-search-results-card {
	padding: 24px 0;
	border-bottom: 1px solid $color-green-light;
}

.our-stories-search-results-card {
	.heading-3 {
		margin-top: 4px;
	}

	.heading-4 a {
		color: inherit;
	}
}

.ossr-card__image-wrapper {
	display: none;
}

.our-stories-container {
	padding: 32px 24px;
}

.our-stories-grid {
	display: grid;
	gap: 32px;
}

.our-stories-card-img {
	width: 100%;
	height: 246px;
	object-fit: cover;
	object-position: top;
}

.our-stories-card-description {
	.heading-4 a {
		color: inherit;
	}
}

.our-stories-modal-category + .our-stories-modal-category {
	margin-top: 24px;
}

.our-stories-modal-category.active a {
	border-bottom: 2px solid $color-brand-primary;
}

.stories-menu-item {
	& + & {
		border-top: 0;
	}
}

.our-stories-show-article-container {
	padding-top: 32px;
	padding-bottom: 32px;

	.w-content-module {
		padding: 0;
		margin: 0;
		max-width: none;
	}

	.content-module__section > *:first-child {
		margin-top: 0;
	}
}

.our-stories-show-article-details__socials {
	margin-top: 24px;
	margin-bottom: 32px;

	a {
		font-size: 18px;
	}

	a + a {
		margin-inline-start: 32px;
	}
}

.our-stories-show-article-tag {
	font-size: 12px;
	margin-inline-end: 12px;
	margin-top: 12px;
	padding: 6px 10px;
	border: 1px solid $color-brand-primary;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 30px;
	transition-property: background-color, color;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;

	&:hover {
		background-color: $color-brand-primary;
		color: $color-white;
	}
}

@include below-laptop {
	.our-stories-featured-article-image-container {
		transform: translate(0, 0);
		margin-bottom: 16px;
		margin-top: 24px;

		img {
			width: 100%;
			height: 210px;
			object-fit: cover;
			object-position: top;
		}
	}
}

@include laptop {
	.our-stories-navigation {
		padding: 0 32px;
	}

	.our-stories-navigation.mobile {
		display: none;
	}

	.our-stories-navigation.laptop {
		display: flex;
	}

	.our-stories-navigation.laptop.our-stories-navigation--full-width {
		width: 100%;
	}

	.our-stories-search-results-container {
		max-width: 906px;
		margin: 0 auto;
		padding: 80px 0;
	}

	.our-stories-search-results-form {
		margin: 40px 0 24px;
	}

	.our-stories-search-results-input {
		font-size: 20px;
		margin: 0 16px;
	}

	.our-stories-search-results-label {
		font-size: 16px;
		padding-bottom: 24px;
		border-bottom: none;
	}

	.our-stories-search-results-card {
		display: flex;
		padding: 16px 0;
	}

	.our-stories-search-results-card {
		.ossr-card__image-wrapper {
			flex-shrink: 0;
			align-self: center;
			display: block;
			margin-inline-end: 16px;
			img {
				width: 254px;
				height: 143px;
				object-fit: cover;
			}
		}

		.heading-3 {
			margin-top: 8px;
		}
	}

	.our-stories-container {
		padding: 80px 32px;
	}

	.our-stories-container--small-padding {
		padding-top: 48px;
	}

	.our-stories-grid {
		grid-template-columns: repeat(3, 1fr);
	}

	.our-stories-show-article-container {
		display: flex;
		padding-top: 120px;
		padding-bottom: 120px;
		max-width: 1206px;
	}

	.our-stories-show-article-details {
		min-width: 304px;
		margin-inline-end: 48px;

		.sticky-wrapper {
			position: sticky;
			top: calc($site-header-height + 32px);
		}
	}
}
