$clinic-profile-preview-info-padding: 24px 10px !default;
$clinic-profile-preview-info-bg: $color-yellow-light !default;
$clinic-profile-preview-info-color: $color-brand-secondary !default;

.clinic-profile-photos {
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
	.clinic-profile-photo {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-size: cover;
		background-repeat: no-repeat;
		height: 208px;
		&__label {
			align-self: flex-start;
			background-color: $color-gray-light;
			cursor: unset;
			padding: 8px 16px;
			margin: 16px 0 0 16px;
			border-radius: 50px;
			&:hover {
				cursor: default;
			}
		}
		&__button {
			width: 40px;
			height: 40px;
			font-size: 20px;
			align-self: flex-end;
			justify-content: center;
			padding: 8px;
			background-color: $color-white;
			border-color: rgba(0, 0, 0, 0);
			border-radius: 50px;
			margin: auto 16px 16px 0;
			&:hover {
				background-color: $color-green-primary;
			}
		}
	}
}

@include small {
	.clinic-profile-photos {
		grid-template-columns: 1fr 1fr;
	}
}

@include below-tablet {
	.clinic__staff-all {
		flex-direction: column;

		.clinic__staff-action {
			margin: 16px 0 0 0;
			@include flip(text-align, left);
			.dropdown__item {
				display: inline-flex;
				height: auto;
				width: auto;
				transition: border 0.2s ease-in-out;
				margin-top: 8px;
				padding-bottom: 2px;
				text-decoration: none;
				border-bottom: 1px solid $color-green-primary;

				&:hover {
					border-color: $color-orange-primary;
					color: $color-green-primary;
				}
			}
		}
	}
}

//- Global
.update-clinic-profile-heading__button {
	margin-top: 32px;
}

//- Clinic Profie Card
.update-clinic-profile-card {
	padding-bottom: 24px;
	border-bottom: 1px solid $update-clinic-profile-card-border-color;

	&:first-child {
		padding-bottom: 16px;
	}

	& + & {
		padding-top: 24px;
	}

	.heading-4 {
		font-size: 12px;
	}

	&--no-border {
		border-bottom: none;
	}
}

.update-clinic-pc__description,
.clinic__about-description {
	p,
	h4,
	ul {
		margin-top: 16px;
		margin-bottom: 0;
	}

	a {
		text-decoration: underline;
		text-underline-offset: 3px;
		cursor: pointer;
		transition: color 0.2s ease-in-out;
	}
}

.update-clinic-profile-edit {
	font-size: 22px;
}

.update-clinic-pc-tags {
	display: flex;
	flex-wrap: wrap;
	margin-top: -8px;

	&__item {
		display: flex;
		flex-basis: 100%;
		align-items: center;
		margin-top: 24px;
		@include flip(margin-right, 24px);

		&-icon {
			color: $color-orange-primary;
			font-size: 24px;
		}

		&-description {
			@include flip(margin-left, 8px);
		}
	}
}

@include media-breakpoint-up(small) {
	.update-clinic-pc-tags__item {
		flex-basis: auto;
	}
}

@include laptop {
	.update-clinic-profile-heading {
		display: flex;
		justify-content: space-between;

		&__button {
			margin-top: 0;
			@include flip(margin-left, 64px);
		}
	}

	.update-clinic-profile-card {
		padding-bottom: 32px;

		&:first-child {
			padding-bottom: 24px;
		}
		& + & {
			padding-top: 32px;
		}
	}

	.clinic-profile-photo:hover .clinic-profile-photo__button {
		opacity: 1;
	}

	.clinic-profile-photo__button {
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}
}

// preview mode
.clinic-profile-preview {
	&--info {
		padding: $clinic-profile-preview-info-padding;
		width: 100%;
		background: $clinic-profile-preview-info-bg;
		color: $clinic-profile-preview-info-color;
		text-align: center;

		> * {
			border-bottom-color: $clinic-profile-preview-info-color;
			color: $clinic-profile-preview-info-color;
		}
	}

	.navigation__search-form,
	.navigation__links,
	.breadcrumb-list li::after,
	.breadcrumb-list li a,
	.input-label,
	.input-with-icon,
	.button--round,
	.clinic-share-button,
	.clinic__map-input {
		pointer-events: none;
		opacity: 0.5;
	}
}
