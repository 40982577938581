@import '../../common/shared/button';
$button-circled-border-radius: 50% !default;

/**
 * Margin is fully around to cover the case when the buttons overflow.
 */
.button-group {
	> .button + .button {
		margin: 12px;
	}
	> .button:first-child {
		@include spacing(left, 0);
	}
	> .button:last-child {
		@include spacing(right, 0);
	}
}

.button {
	@extend %reset-button;
	display: inline-flex;
	align-items: center;
	height: $button-height;
	padding: 0 24px;
	border-radius: $button-border-radius;
	color: #fff;
	@include transition('background, color', 0.2s);

	&:disabled,
	&.disabled {
		opacity: $primary-button-disabled;
		cursor: initial;
		pointer-events: none;
	}
}

.button--small {
	height: $button-height-small;
	padding: 0 $button-padding-small;
}

.button--with-icon {
	> .icon {
		font-size: 20px;
	}

	> span + .icon,
	> .icon + span {
		margin-inline-start: 16px;
	}
}

.button--primary {
	background-color: $button-primary-background;
	color: #fff;

	&:hover {
		background-color: $button-primary-hover-background;
		color: #fff;
	}

	&:focus-visible {
		background-color: $button-primary-focus-background;
		color: #fff;
	}

	&[disabled] {
		background-color: $button-primary-disable-background;
		color: #fff;
	}
}

.button--secondary {
	border: 1px solid $button-secondary-border-color;
	background-color: $button-secondary-background;
	color: $button-secondary-color;

	&:hover,
	&:focus-visible {
		border-color: $button-secondary-hover-background;
		background-color: $button-secondary-hover-background;
		color: #fff;
	}

	&[disabled] {
		border-color: $button-secondary-disable-color;
		background-color: $button-secondary-disable-background;
		color: $button-secondary-disable-color;
	}
}

.button--secondary-active {
	border-color: $button-secondary-active-border-color;
	background-color: $button-secondary-active-background;
	color: #fff;
}

// TODO: Implement this class properly
.button--secondary-icon {
	@extend .button--secondary;
	border: none;

	.icon {
		font-size: 21px;
	}
}

%button--underline {
	height: $button-underline-height;
	padding: 0 0 2px;
	transition: border 0.2s ease-in-out;
	border-radius: 0;
	border-bottom: 1px solid $button-underline-color;
	color: $button-underline-color;
	font-size: 20px;
	line-height: 1.4;
}

.button--underline {
	@extend %button--underline;

	&:hover,
	&:focus {
		border-bottom-color: $button-underline-hover-color;
		color: $button-underline-color;
	}

	&[disabled] {
		border-bottom-color: $button-underline-disable-color;
		color: $button-underline-disable-color;
	}

	&.button--small {
		height: $button-underline-small-height;
		font-size: 16px;
	}
}

/**
 * Underline button but with a span. Used for dropdown with chevron icon.
 */

.button-underline-with-icon {
	@extend %reset-button;

	.button-underline-with-icon__text {
		@extend %button--underline;
	}

	&:hover,
	&:focus {
		.button-underline-with-icon__text {
			border-bottom-color: $button-underline-hover-color;
			color: $button-underline-color;
		}
	}

	&[disabled] {
		.button-underline-with-icon__text {
			border-bottom-color: $button-underline-disable-color;
			color: $button-underline-disable-color;
		}
	}

	&.button--small {
		height: auto;
		padding: 0;

		.button-underline-with-icon__text {
			height: $button-underline-small-height;
			font-size: 16px;
		}
	}
}

.button--primary-text {
	padding: 0 0 2px;
	transition: border 0.2s ease-in-out;
	border-radius: 0;
	color: $button-primary-text-color;

	&.hover,
	&:hover,
	&:focus {
		color: $button-primary-text-hover-color;
	}

	&[disabled] {
		color: $button-primary-text-disabled;
	}
}

.button--round {
	position: relative;
	padding-inline-start: $button-round-padding-start;
	padding-inline-end: $button-round-padding-end;
	height: $button-round-size;
	display: inline-flex;
	align-items: center;
	line-height: $button-round-line-height;
	@include transition('padding, color', 0.2s, linear);

	> * {
		position: relative;
		z-index: 2;
	}

	&::before {
		position: absolute;
		content: '';
		top: 0;
		@include flip(left, 0);
		@include flip(right, initial);
		left: 0;
		width: $button-round-size;
		height: $button-round-size;
		background-color: $button-primary-background;
		border-radius: $button-round-size / 2;
		transition: width 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	}

	&::after {
		position: absolute;
		content: '';
		width: $button-round-icon-size;
		height: $button-round-icon-size;
		top: ($button-round-size - $button-round-icon-size) / 2;

		@include flip(left, ($button-round-size - $button-round-icon-size) / 2 + 1);
		@include flip(right, initial);

		// background-image: url('../../../../icons/dholiday/chevron-right-white.svg');
		background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18.03 12.007 7.425 22.613 6.01 21.2l9.193-9.193L6.01 2.814 7.424 1.4l10.607 10.607Z' fill='%23fff' /%3E%3C/svg%3E");
		@include rtl {
			// background-image: url('../../../../icons/dholiday/chevron-left-white.svg');
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.89337 12.3996L15.5 23.0062L16.9142 21.592L7.72145 12.3992L16.9142 3.20718L15.5 1.79297L4.89337 12.3996Z' fill='%23fff'/%3E%3C/svg%3E");
		}
		@include transition(
			'margin-inline-start, left, right',
			0.4s,
			cubic-bezier(0.445, 0.05, 0.55, 0.95)
		);
	}

	&.hover,
	&:hover {
		padding-top: $button-round-padding-top-bottom;
		padding-bottom: $button-round-padding-top-bottom;
		padding-inline-start: $button-round-padding-end;
		padding-inline-end: $button-round-padding-end-hover;
		color: #fff;

		&::before {
			width: 100%;
		}

		&::after {
			margin-inline-start: -$button-round-icon-size - $button-round-margin;
			@include flip(left, 100%);
			@include flip(right, initial);
		}
	}
}

.button--stretch {
	height: auto;
}

.button--block {
	width: 100%;
	justify-content: center;
}

.button-circle {
	@extend %reset-button;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 42px;

	border-radius: $button-circled-border-radius;
	font-size: 12px;

	@include transition('color, border-color, background-color', 0.3s);

	@include media-breakpoint-down(pico) {
		width: 34px;
		height: 34px;
	}

	&[disabled] {
		color: $button-circled-disabled-color;
		border-color: $button-circled-disabled-border-color;
	}

	&.active {
		color: #fff;
		border-color: $color-brand-secondary;
		background-color: $color-brand-secondary;
	}

	&--large {
		width: 56px;
		height: 56px;
		font-size: 22px;
	}
}

.day-filter-button {
	@extend .button-circle;
	@extend .button--secondary;
}

.reset-button-color {
	color: $color-brand-primary;
}

@include below-laptop {
	.button--mobile-full-width {
		width: 100%;
		justify-content: center;
	}
}
